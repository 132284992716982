import "../../assets/css/modal.css"
import React, { useEffect, useState } from "react"
import { Modal } from "@mui/material"
import CloseCircle from "../../assets/images/close-circle.png"
import { ReactComponent as ArrowRight } from "../../assets/images/run-payroll/arrow-right-big.svg"
import { ReactComponent as CheckCircle } from "../../assets/images/run-payroll/ic_check_circle.svg"
import toastService from "../../services/toastService"
import axios from "../../axios"
import AddMissingEmployeeModal from "./AddMissingEmployeeModal"
import SelectWithSearch from "../UI/selectWithSearch/SelectWithSearch"
import DarkTooltip from "../common/DarkToolTip"
import Loading from "../common/Loading"

import "./match-employee-modal.scss"

const MatchEmployeeModal = ({
  open,
  handleClose,
  handleConfirm,
  needMatchEmployeeList,
  setNeedMatchEmployeeList,
  isLoading
}) => {
  const [matchesName, setMatchesName] = useState()
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [employeeList, setEmployeeList] = useState([])

  const isSomeOfItemsMatched = needMatchEmployeeList.some((el) => el?.matched?.id)

  useEffect(() => {
    if (open) {
      axios
        .get("/employee?selectOption=true")
        .then((response) => {
          if (response.success) {
            setEmployeeList(response.data)
          }
        })
        .catch((err) => toastService.error(err.response?.data?.message || err.message))
    }
    if (!open && employeeList) {
      setEmployeeList([])
    }
  }, [open])

  const handleClickConfirm = () => {
    if (needMatchEmployeeList.some((el) => !el?.matched?.id)) {
      setShowErrors(true)
      const findFirstEmptyEl = needMatchEmployeeList.find((el) => !el?.matched?.id)

      setTimeout(() => {
        const targetElm = document.getElementById(findFirstEmptyEl.employee.name)
        targetElm.scrollIntoView()
      }, 500)

      return
    }
    showErrors && setShowErrors(false)
    handleConfirm()
  }

  const handleAddMatchEmployee = (matchForName, employeeData) => {
    const curMatchForName = matchesName || matchForName
    const newNeedMatchEmployeeList = needMatchEmployeeList.reduce((acc, el) => {
      if (el?.employee?.name === curMatchForName) {
        const fullName = `${employeeData.first_name} ${employeeData.last_name}`

        return [
          ...acc,
          {
            employee: { name: el?.employee?.name },
            matched: {
              id: employeeData.id,
              name: fullName,
              source: "file",
              match_id: el?.matched?.match_id
            }
          }
        ]
      }

      return [...acc, el]
    }, [])
    matchesName && setMatchesName()
    setNeedMatchEmployeeList(newNeedMatchEmployeeList)
  }

  const handleAddEmployeeOpen = (matchName) => {
    setMatchesName(matchName)
    setAddEmployeeOpen(!addEmployeeOpen)
  }

  const handleAddEmployeeClose = () => {
    matchesName && setMatchesName()
    setAddEmployeeOpen(!addEmployeeOpen)
  }

  const handleAddConfirm = (newEmployee) => {
    setEmployeeList([...employeeList, newEmployee])
    handleAddMatchEmployee(matchesName, newEmployee)
    setAddEmployeeOpen(!addEmployeeOpen)
  }

  const selectedEmployees = needMatchEmployeeList?.reduce((acc, el) => {
    if (el.matched?.id) {
      return [...acc, el.matched?.id]
    }
    return [...acc]
  }, [])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        style={{ ...(addEmployeeOpen ? { display: "none" } : {}) }}>
        <div className="confirm-modal missing-employee">
          <div className="confirm-modal-wrapper">
            <button className="popup-close edit-popup-cancel" onClick={handleClose}>
              <img src={CloseCircle} alt="" />
            </button>
            Match Unidentified Employees
            <div className="confirm-modal-subtitle missing-employee">
              Some employees could not be matched during the DSW upload. Please review the missing
              employees and assign them manually to proceed
            </div>
            <div className="matches-employee-title">
              <div>Missing Employees</div>
              <div>Employee Match</div>
            </div>
            <div className="matches-employee-list">
              {needMatchEmployeeList.map((el) => (
                <div
                  key={el?.employee?.name}
                  id={el?.employee?.name}
                  className="matches-employee-list-item">
                  <div className="matches-employee-list-item-name">
                    <div>{el?.employee?.name}</div>
                    <ArrowRight />
                  </div>
                  <div className="matches-employee-list-item-select">
                    <SelectWithSearch
                      sx={{ width: isSomeOfItemsMatched ? "calc(100% - 24px)" : "100%" }}
                      value={el?.matched?.id}
                      setValue={handleAddMatchEmployee}
                      putPropToSave={el?.employee?.name}
                      onButtonClick={handleAddEmployeeOpen.bind(null, el?.employee?.name)}
                      placeholder={"Search employee"}
                      options={employeeList}
                      optionsWitchShow={selectedEmployees}
                      user
                      noAvatar
                      error={(!el?.matched || !el?.matched?.id) && showErrors}
                    />
                    {(el?.matched?.source === "db" || el?.matched?.source === "file") && (
                      <DarkTooltip
                        title={
                          el?.matched?.source === "db"
                            ? "Matched based on previous selection"
                            : "Automatically matched"
                        }
                        enterNextDelay={500}
                        enterDelay={500}
                        placement="top"
                        arrow>
                        <CheckCircle className={el?.matched?.source === "db" ? "db" : ""} />
                      </DarkTooltip>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="confirm-modal-btn-wrapper">
              <button className="confirm-modal-btn cancel" onClick={handleClose}>
                Cancel
              </button>
              <button
                className="confirm-modal-btn recalculate"
                disabled={isLoading}
                onClick={handleClickConfirm}>
                {isLoading ? <Loading /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <AddMissingEmployeeModal
        open={addEmployeeOpen}
        handleClose={handleAddEmployeeClose}
        isMatchesModal
        handleSaveMatchEmployeeId={handleAddConfirm}
      />
    </>
  )
}

export default MatchEmployeeModal
