import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  payrollList: []
};

export const { actions, reducer } = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    setPayrollList: (state, action) => {
      state.payrollList = action.payload;
    },
    startOverPayroll: (state, action) => {
      state.payrollList = state.payrollList.map((el) => {
        if (el.id !== +action.payload) return el;

        return {
          ...el,
          statements_count: 0,
        }
      });
    },
    resetPayroll: (state) => {
      state.payrollList = [];
    }
  }
});

export default reducer;
