import { isUserSourceRestricted, isUserPackageRoute } from "../../helpers/helpers";

const videoGeneralFW = "https://www.youtube.com/watch?v=LPyqVbDsYgw"

const isUserRestricted = isUserSourceRestricted();

const timeCardStepPackageRouteOption = {
  name: "Package Route",
  value: 'package-route'
};

const stepperData = {
  filesMissing: {
    title: "Files Required",
    description: "To generate payroll, please upload either a Charge Statement or a Weekly Service Worksheet. Calculation cannot proceed without at least one of these files."
  },
  chargeStatementStep: {
    name: "Charge Statement",
    title: "Upload Charge Statement from MyBizAccount",
    description: "Start your payroll process by uploading your Charge Statement",
    benefits: [
      "Gross Profit",
      "Revenue",
      "Packages",
      "Per Stop Expense",
      "W-2 Calculation",
      "Stops",
      "Per Stop Revenue",
      "Days Worked",
      "E-Commerce"
    ],
    uploadText: "Click or drag a CSV, XSL or XSLX file to this area to upload",
    help: {
      title: "Not sure how to download the Charge Statement?",
      description: null,
      steps: [
        "Go to Charge Statement in MyBizAccount",
        "Select Excel/CSV as the file format",
        "Choose CSV Text File to download"
      ],
      video: {
        description: "Need more help? Watch the video",
        url: videoGeneralFW
      }
    },
    fileFormats: ".csv, .xlsx, .xls"
  },
  wswStep: {
    name: "Weekly Service Worksheet",
    title: "Upload Weekly Service Worksheet from MyBizAccount",
    description:
      "Keep your records accurate and up-to-date by uploading the Weekly Service Worksheet directly from MyBizAccount",
    benefits: [
      "Hours Worked",
      "All Status Code Package",
      "Packages",
      "Early/Late Pickup",
      "Code 85",
      "Stops",
      "Missed Pickup",
      "W-2 Calculation",
      "Inbound Local Scan",
      "Did Not Attempt",
      "Days Worked",
      "Work Area"
    ],
    uploadText: "Click or drag a XSL or XSLX file to this area to upload",
    help: {
      title: "Not sure where to find the WSW file?",
      description: null,
      steps: [
        "Go to Daily Service Worksheet",
        "Select Weekly Service Worksheet",
        "Choose the Week-Ending Date",
        "Click Search",
        "Download the file by clicking Excel on the right side of the screen",
      ],
      video: {
        description: "Need more help? Watch the video",
        url: videoGeneralFW
      }
    },
    fileFormats: ".xlsx, .xls"
  },
  timeCardStep: {
    name: "Time Card",
    title: "Upload Time Card",
    description: "Choose your preferred time card company from the dropdown menu before uploading",
    benefits: null,
    uploadText: "Click or drag a CSV, XSL or XSLX file to this area to upload",
    help: null,
    select: {
      name: 'Time Card',
      placeholder: "Select",
      options: isUserRestricted && isUserPackageRoute()
        ? [timeCardStepPackageRouteOption]
        : [
          {
            name: "GroundCloud",
            value: 'ground-cloud'
          },
          {
            name: "Beans Route",
            value: 'beans-route'
          },
          timeCardStepPackageRouteOption
        ]
    },
    fileFormats: ".csv, .xlsx, .xls"
  },
  fuelStep: {
    name: "Fuel",
    title: "Upload Fuel Data",
    description:
      "Calculates fuel usage per driver to ensure precise profitability insights for each driver",
    benefits: null,
    help: null,
    uploadText: "Click or drag a CSV, XSL or XSLX file to this area to upload",
    select: {
      name: 'Fuel Company',
      placeholder: "Select",
      options: [
        {
          name: "Wex",
          value: 'wex'
        },
        {
          name: "RCPA",
          value: 'rcpa'
        },
        {
          name: "Sunoco",
          value: 'sunoco'
        },
        {
          name: "Coast",
          value: 'coast'
        }
      ]
    },
    fileFormats: ".csv, .xlsx, .xls"
  }
}

export default stepperData
