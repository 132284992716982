import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  token: '',
  isDemoMode: false
};

export const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.user = action.payload;
    },
    updateUserData: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserCompaniesCount: (state, action) => {
      state.user.companies_count = action.payload;
      localStorage.setItem("user", JSON.stringify(state.user || {}));
    },
    setUserCsaCount: (state, action) => {
      state.user.csa_count = action.payload;
      localStorage.setItem("user", JSON.stringify(state.user || {}));
    },
    setUserUnreadNotificationsCount: (state) => {
      state.user.count_unread_notifications = 0;
    },
    setUserIsPayrollCompanyConnected: (state) => {
      state.user.is_payroll_company_connected = true;
    },
    setUserIsSubscribed: (state, action) => {
      state.user.is_subscribed = action.payload;
    },
    logOutUser: (state) => {
      state.user = {};
      state.token = '';
    },
    isDemoModeChange: (state, action) => {
      state.isDemoMode = action.payload;
    }
  }
});

export default reducer;
