import { TextField } from "@mui/material"
import { useSelector } from "react-redux"
import { useEffect, useMemo } from "react"
import InputMask from "react-input-mask"

import { getCompanies, getPositions, getStations } from "../../store/data/data.selectors"
import { getIsDemoMode } from "../../store/auth/auth.selectors"
import InputField from "../common/InputField"
import SelectField from "../common/SelectField"

const EmployeeFormDetails = ({
  errors,
  setValue,
  isActive,
  register,
  touchedFields,
  editedUserId,
  phone,
  setPhone,
  isPaychex,
  primary_company,
  primary_station,
  company_position_id,
  loading,
  addingEmployeeData
}) => {
  const isDemoMode = useSelector(getIsDemoMode)
  const positions = useSelector(getPositions)
  const companies = useSelector(getCompanies)
  const stations = useSelector(getStations)

  const curCompanies = useMemo(() => (open ? companies : []), [open, companies])
  const curPositions = useMemo(() => (open ? positions : []), [open, positions])
  const curStations = useMemo(
    () => stations.filter((el) => el.company_id === primary_company),
    [open, primary_company, stations]
  )

  const handleCompanyStationChange = (e) => {
    const targetValue = e.target?.value

    setValue("primary_station", targetValue, {
      shouldValidate: true,
      shouldDirty: true
    })
    setValue("contract_service_area", targetValue, {
      shouldValidate: true,
      shouldDirty: true
    })
    setValue("station_id", curStations.find((el) => el.csa === targetValue).id, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleCompanyChange = (e) => {
    const targetValue = e.target?.value

    setValue("primary_company", targetValue, {
      shouldValidate: true,
      shouldDirty: true
    })

    setValue("primary_station", null, {
      shouldDirty: true
    })

    setValue("station_id", null, {
      shouldDirty: true
    })
  }

  const handleNumberChange = (inputName, e) => {
    const value = e.target?.value.replace(/[^\d.]/g, "").replace(/\.([.\d]+)$/, function (m, m1) {
      return "." + m1.replace(/\./g, "")
    })
    setValue(inputName, value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  useEffect(() => {
    if (curCompanies.length === 1 && !primary_company && !primary_station && !loading) {
      handleCompanyChange({
        target: {
          value: curCompanies[0].id
        }
      })
    }
  }, [curCompanies, loading, addingEmployeeData])

  useEffect(() => {
    if (curStations.length === 1 && !primary_station) {
      handleCompanyStationChange({
        target: {
          value: curStations[0].csa
        }
      })
    }
  }, [curStations, primary_station])

  return (
    <>
      <InputField
        title="First Name *"
        placeholder="First Name"
        name="first_name"
        errorMessage={errors.first_name?.message}
        isError={errors.first_name?.message}
        isTouched={touchedFields.first_name}
        otherProps={register("first_name")}
        disabled={!isActive}
        fullWidth
      />
      <InputField
        title="Last Name *"
        placeholder="Last Name"
        name="last_name"
        errorMessage={errors.last_name?.message}
        isError={errors.last_name?.message}
        isTouched={touchedFields.last_name}
        className={isDemoMode && editedUserId ? "blur-text" : ""}
        otherProps={register("last_name")}
        required
        disabled={!isActive}
        fullWidth
      />
      <InputField
        title="Email"
        type="email"
        placeholder="Email"
        name="email"
        errorMessage={errors.email?.message}
        isError={errors.email?.message}
        isTouched={touchedFields.email}
        className={isDemoMode && editedUserId ? "blur-text" : ""}
        otherProps={register("email")}
        disabled={!isActive}
        fullWidth
      />

      <div className="form-item">
        <label>Phone Number</label>
        <div className={isDemoMode && editedUserId ? "blur-text input-box" : "input-box"}>
          <InputMask
            mask="(999) 999-9999"
            value={phone}
            disabled={false}
            onChange={(data) => setPhone(data.target?.value)}
            maskChar=" ">
            {() => <TextField className="input-mask" />}
          </InputMask>
        </div>
      </div>

      <InputField
        title="FedEx ID *"
        placeholder="FedEx ID"
        name="fedex_id"
        errorMessage={errors.fedex_id?.message}
        isError={errors.fedex_id?.message}
        isTouched={touchedFields.fedex_id}
        className={isDemoMode && editedUserId ? "blur-text" : ""}
        otherProps={register("fedex_id")}
        disabled={!isActive}
        required
        fullWidth
      />
      <InputField
        title="Employee ID"
        placeholder="Employee ID"
        name="employee_id"
        errorMessage={errors.employee_id?.message}
        isError={errors.employee_id?.message}
        isTouched={touchedFields.employee_id}
        className={isDemoMode && editedUserId ? "blur-text" : ""}
        otherProps={register("employee_id")}
        disabled={!isActive || isPaychex}
        fullWidth
      />

      <SelectField
        title="Primary Company *"
        name="primary_company"
        value={primary_company}
        isError={errors.primary_company?.message}
        otherProps={register("primary_company")}
        required
        disabled={!isActive}
        options={curCompanies}
        onChange={handleCompanyChange}
      />

      <SelectField
        title="Primary Station *"
        name="primary_station"
        value={primary_station}
        isError={errors.primary_station?.message}
        otherProps={register("primary_station")}
        required
        disabled={!primary_company || !isActive}
        options={curStations}
        onChange={handleCompanyStationChange}
        optionValueName="csa"
        doNotHideIfNoOptions
      />

      <InputField
        title="Contract Service Area *"
        placeholder="Contract Service Area"
        name="contract_service_area"
        errorMessage={errors.contract_service_area?.message}
        isError={errors.contract_service_area?.message}
        isTouched={touchedFields.contract_service_area}
        className={isDemoMode && editedUserId ? "blur-text" : ""}
        otherProps={register("contract_service_area")}
        onChange={handleNumberChange.bind(null, "contract_service_area")}
        disabled
        fullWidth
        required
      />

      <SelectField
        title="Company Position"
        name="company_position_id"
        value={company_position_id}
        otherProps={register("company_position_id")}
        disabled={!isActive}
        options={curPositions}
        isOnlySelectError={
          touchedFields.company_position_id && !!errors.company_position_id?.message
        }
      />
    </>
  )
}

export default EmployeeFormDetails
