import React from "react"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import { ReactComponent as TableDownArrow } from "../../../assets/images/settings/arrow-down.svg"
import "./styles.scss"

const HelpAccordion = ({ data }) => {
  return (
    <Accordion className="custom-accordion">
      <AccordionSummary expandIcon={<TableDownArrow />}>{data.title}</AccordionSummary>
      <AccordionDetails>
        {data.description ? <div className="description">{data.description}</div> : null}
        {data.steps
          ? data.steps.map((step, index) => (
              <div key={step} className="step">
                {index + 1}. {step}
              </div>
            ))
          : null}
        {data.video ? (
          <a href={data.video.url} target="_blank" rel="noreferrer">
            {data.video.description}
          </a>
        ) : null}
      </AccordionDetails>
    </Accordion>
  )
}

export default HelpAccordion
