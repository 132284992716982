import { useDispatch } from "react-redux"
import { useState } from "react"

import axios from "../../axios"
import Loading from "../common/Loading"
import toastService from "../../services/toastService"
import { actions as authActions } from "../../store/auth/auth.reducers"
import CustomCheckbox from "../common/CustomCheckbox"

const EmployeeFormFooter = ({
  handleSaveMatchEmployeeId,
  handleCurrentClose,
  setSaveLoading,
  setIsEditComplete,
  setIsAddComplete,
  setTab,
  setAvatar,
  avatar,
  isLastAddMissingEmployee,
  isAddMissingEmployeeModal,
  handleSubmit,
  saveLoading,
  setValue,
  reset,
  customErrors,
  isActive,
  editedUserId,
  editedUser,
  phone,
  deleted_code_85_deduction_bonus,
  deleted_stop_bonuses,
  deleted_package_bonuses,
  code_85_deduction_bonus,
  ils_addition_bonus,
  add_ils_addition_bonus,
  ils_deduction_bonus,
  add_ils_deduction_bonus,
  all_status_code_package_deduction_bonus,
  add_all_status_code_package_deduction_bonus,
  dna_deduction_bonus,
  add_dna_deduction_bonus,
  miss_pickup_window_deduction_bonus,
  add_miss_pickup_window_deduction_bonus,
  early_late_pickup_deduction_bonus,
  add_early_late_pickup_deduction_bonus
}) => {
  const dispatch = useDispatch()
  const [isRecalculate, setIsRecalculate] = useState(false)

  const fetchUserData = () =>
    axios
      .get("/auth/user")
      .then((response) => {
        dispatch(authActions.loginUser(response))
        localStorage.setItem("user", JSON.stringify(response || {}))
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))

  const onSubmit = (values) => {
    const curStopBonuses =
      values.stop_bonuses
        ?.filter((el) => el.per_stop !== "" || el.daily_threshold !== "")
        .map((el) => (el.id ? el : { ...el, id: 0, per_item: "stop" })) || []
    const curPackageBonuses =
      values.package_bonuses
        ?.filter((el) => el.per_stop !== "" || el.daily_threshold !== "")
        .map((el) => (el.id ? el : { ...el, id: 0, per_item: "package" })) || []
    const body = {
      ...values,
      recalculate_payroll: isRecalculate,
      ...(editedUserId && { id: editedUserId }),
      ...((curStopBonuses || curPackageBonuses) && {
        bonuses: [...curStopBonuses, ...curPackageBonuses]
      }),
      ...(phone && { phone_no: phone }),
      deleted_bonus_adjustments: deleted_code_85_deduction_bonus,
      deleted_bonuses: [...deleted_stop_bonuses, ...deleted_package_bonuses],
      bonus_adjustments: [
        ...(code_85_deduction_bonus || []),
        ...(!ils_addition_bonus?.id &&
        (ils_addition_bonus?.field_value || ils_addition_bonus?.price_value) &&
        !!add_ils_addition_bonus
          ? [
              {
                id: 0,
                action_type: "addition",
                bonus_type: "default",
                field: "ils",
                field_value: ils_addition_bonus?.field_value || 0,
                price_type: ils_addition_bonus?.price_type || "amount",
                price_value: ils_addition_bonus?.price_value || 0
              }
            ]
          : ils_addition_bonus?.id
            ? [ils_addition_bonus]
            : []),
        ...(!ils_deduction_bonus?.id &&
        (ils_deduction_bonus?.field_value || ils_deduction_bonus?.price_value) &&
        !!add_ils_deduction_bonus
          ? [
              {
                id: 0,
                action_type: "deduction",
                bonus_type: "default",
                field: "ils",
                field_value: ils_deduction_bonus?.field_value || 0,
                price_type: ils_deduction_bonus?.price_type || "amount",
                price_value: ils_deduction_bonus?.price_value || 0
              }
            ]
          : ils_deduction_bonus?.id
            ? [ils_deduction_bonus]
            : []),
        ...(!all_status_code_package_deduction_bonus?.id &&
        (all_status_code_package_deduction_bonus?.field_value ||
          all_status_code_package_deduction_bonus?.price_value) &&
        !!add_all_status_code_package_deduction_bonus
          ? [
              {
                id: 0,
                action_type: "deduction",
                bonus_type: "default",
                field: "all_status_code_package",
                field_value: all_status_code_package_deduction_bonus?.field_value || 0,
                price_type: all_status_code_package_deduction_bonus?.price_type || "amount",
                price_value: all_status_code_package_deduction_bonus?.price_value || 0
              }
            ]
          : all_status_code_package_deduction_bonus?.id
            ? [all_status_code_package_deduction_bonus]
            : []),
        ...(!dna_deduction_bonus?.id &&
        (dna_deduction_bonus?.field_value || dna_deduction_bonus?.price_value) &&
        !!add_dna_deduction_bonus
          ? [
              {
                id: 0,
                action_type: "deduction",
                bonus_type: "default",
                field: "dna",
                field_value: dna_deduction_bonus?.field_value || 0,
                price_type: dna_deduction_bonus?.price_type || "amount",
                price_value: dna_deduction_bonus?.price_value || 0
              }
            ]
          : dna_deduction_bonus?.id
            ? [dna_deduction_bonus]
            : []),
        ...(!miss_pickup_window_deduction_bonus?.id &&
        (miss_pickup_window_deduction_bonus?.field_value ||
          miss_pickup_window_deduction_bonus?.price_value) &&
        !!add_miss_pickup_window_deduction_bonus
          ? [
              {
                id: 0,
                action_type: "deduction",
                bonus_type: "default",
                field: "miss_pickup_window",
                field_value: miss_pickup_window_deduction_bonus?.field_value || 0,
                price_type: miss_pickup_window_deduction_bonus?.price_type || "amount",
                price_value: miss_pickup_window_deduction_bonus?.price_value || 0
              }
            ]
          : miss_pickup_window_deduction_bonus?.id
            ? [miss_pickup_window_deduction_bonus]
            : []),
        ...(!early_late_pickup_deduction_bonus?.id &&
        (early_late_pickup_deduction_bonus?.field_value ||
          early_late_pickup_deduction_bonus?.price_value) &&
        !!add_early_late_pickup_deduction_bonus
          ? [
              {
                id: 0,
                action_type: "deduction",
                bonus_type: "default",
                field: "early_late_pickup",
                field_value: early_late_pickup_deduction_bonus?.field_value || 0,
                price_type: early_late_pickup_deduction_bonus?.price_type || "amount",
                price_value: early_late_pickup_deduction_bonus?.price_value || 0
              }
            ]
          : early_late_pickup_deduction_bonus?.id
            ? [early_late_pickup_deduction_bonus]
            : [])
      ]
    }

    if (editedUserId) {
      setSaveLoading(true)
      if (avatar?.file) {
        axios
          .post(`/employee/${editedUserId}/avatar`, avatar.file)
          .then(() => {
            axios
              .put("/employee", body)
              .then((response) => {
                if (response.success) {
                  toastService.success("Employee successfully edited")
                  setIsEditComplete && setIsEditComplete(true)
                  handleCurrentClose()
                  fetchUserData()
                }
              })
              .catch((err) => {
                const message = err.response?.data?.errors
                  ? Object.values(err.response?.data?.errors).join("\n")
                  : err.response?.data?.message || err.message

                toastService.error(message)
              })
          })
          .catch((err) => {
            if (err.response?.status === 422 || err.code === "ERR_NETWORK") {
              setAvatar()
              toastService.error("File is too large, try again with a smaller file")
            } else {
              toastService.error(err.response?.data?.message || err.message)
            }
          })
          .finally(() => setSaveLoading(false))
      } else {
        axios
          .put("/employee", body)
          .then((response) => {
            if (response.success) {
              toastService.success("Employee successfully edited")
              setIsEditComplete && setIsEditComplete(true)
              handleCurrentClose()
              fetchUserData()
            }
          })
          .catch((err) => {
            const message = err.response?.data?.errors
              ? Object.values(err.response?.data?.errors).join("\n")
              : err.response?.data?.message || err?.message
            toastService.error(message)
          })
          .finally(() => setSaveLoading(false))
      }
    } else {
      setSaveLoading(true)

      axios
        .post("/employee", body)
        .then((response) => {
          if (response.success) {
            handleSaveMatchEmployeeId && handleSaveMatchEmployeeId(response.data?.employee)
            if (avatar?.file) {
              axios
                .post(`/employee/${response.data?.employee?.id}/avatar`, avatar.file)
                .then(() => {
                  handleEmployeeSuccessfullyAdded()
                })
                .catch((err) => toastService.error(err.response?.data?.message || err.message))
            } else {
              handleEmployeeSuccessfullyAdded()
            }
          }
        })
        .catch((err) => {
          const message = err.response?.data?.errors
            ? Object.values(err.response?.data?.errors).join("\n")
            : err.response?.data?.message || err.message
          toastService.error(message)
        })
        .finally(() => setSaveLoading(false))
    }
  }

  const handleEmployeeSuccessfullyAdded = () => {
    if (!isLastAddMissingEmployee) {
      toastService.success("Employee successfully added")
    }

    if (isAddMissingEmployeeModal) {
      reset()
      setValue("primary_company", null, { shouldDirty: true })
      setValue("primary_station", null, { shouldDirty: true })
      setValue("station_id", null, { shouldDirty: true })
      setAvatar()
      setTab("details")
    } else {
      handleCurrentClose()
    }
    setIsEditComplete && setIsEditComplete(true)
    setIsAddComplete && setIsAddComplete(true)
  }

  const handleRecalculateChange = () => setIsRecalculate(!isRecalculate)
  return (
    <>
      {isAddMissingEmployeeModal ? (
        <div className="add-missing-employee-btn-group">
          <button className="btn-style blank-btn employee-btn cancel" onClick={handleCurrentClose}>
            Cancel
          </button>
          <button
            onClick={handleSubmit(onSubmit)}
            className="btn-style save employee-btn"
            type="submit"
            disabled={saveLoading || Object.keys(customErrors).length}>
            {!saveLoading ? (
              <>{isLastAddMissingEmployee ? "Save" : "Save & Next"}</>
            ) : (
              <div style={{ height: "0vh" }}>
                <Loading style={{ width: "33px", height: "33px" }} />
              </div>
            )}
          </button>
        </div>
      ) : (
        <div className="popup-btns-sec employees-modal">
          <div>
            <CustomCheckbox
              checked={isRecalculate}
              disabled={saveLoading}
              onChange={handleRecalculateChange}
              label={
                isRecalculate && saveLoading ? (
                  <span className="dark-tooltip-text">Recalculating...</span>
                ) : (
                  <span onClick={handleRecalculateChange} className="dark-tooltip-text">
                    Recalculate Latest Payroll
                  </span>
                )
              }
            />
            <span className="employee-more-info small">
              Automatically update payroll calculations using the new rates
            </span>
          </div>

          <div className="employee-btn-group">
            <button
              className="btn-style blank-btn employee-btn cancel"
              onClick={handleCurrentClose}>
              Cancel
            </button>
            <button
              onClick={handleSubmit(onSubmit)}
              className="btn-style employee-btn save"
              type="submit"
              disabled={saveLoading || !isActive || Object.keys(customErrors).length}>
              {!saveLoading ? (
                <>{editedUser ? "Save" : "Add"}</>
              ) : (
                <div style={{ height: "0vh" }}>
                  <Loading style={{ width: "33px", height: "33px" }} />
                </div>
              )}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default EmployeeFormFooter
