import "../../assets/css/modal.css";
import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../axios";
import toastService from "../../services/toastService";
import Loading from "../common/Loading";
import InputField from "../common/InputField";

const schema = yup.object().shape({
  value: yup.string().required("Email is required!")
});

const EmployeeEmailReportModal = ({ open, handleClose, employee, payrollId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, touchedFields },
  } = useForm({
    mode: "all",
    defaultValues: {
      value: "",
    },
    resolver: yupResolver(schema),
  });


  useEffect(() => {
    setValue("value", employee?.email, {
      shouldValidate: true,
      shouldDirty: true
    });
  }, [open]);

  const handleMainClose = () => {
    handleClose();
    reset();
  };

  const onSubmit = () => {
    setIsLoading(true);

    axios
        .post(`/payroll/${payrollId}/send-week-report-for-employee/${employee.id}`, {
          method: 'email',
          value: getValues("value"),
        })
        .then((response) => {
          if (response.success) {
            toastService.success("Report was sent successfully.");
            handleMainClose();
          }
        })
        .catch((err) => {
          const message = err.response?.data?.errors
              ? Object.values(err.response?.data?.errors).join("\n")
              : err.response?.data?.message || err?.message;
          toastService.error(message);
        })
        .finally(() => {
          setIsLoading(false);
        });
  };

  return (
    <Modal open={open} onClose={handleMainClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper add-member-modal">
          <form className="profile-form-sec add-member-modal-form">
            <h3 style={{ fontSize: "18px", marginBottom: "5px", fontWeight: "800" }}>Email Report</h3>
            <span style={{ fontSize: "12px", fontWeight: "400"}}>Enter email to send report</span>

            <div className="form-item" style={{ marginBottom: "0", marginTop: "1.5rem"}}>
              <div className="input-box">
                <InputField
                    type="email"
                    placeholder="Email"
                    name="value"
                    errorMessage={errors.value?.message}
                    isError={errors.value?.message}
                    isTouched={touchedFields.value}
                    otherProps={register("value")}
                    fullWidth
                />
              </div>
            </div>
          </form>

          <div className="add-member-form-btn-wrapper">
            <button className="btn-style blank-btn add-popup-cancel" onClick={handleMainClose}>
              Cancel
            </button>
            <button
              className="btn-style add-employee-btn loading-style"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}>
              {isLoading ? <Loading /> : "Send"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmployeeEmailReportModal;
