import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";


dayjs.extend(weekday);

export const getPayAdjustment = (employee) => {
    return employee?.rates?.weekly_rate_w2 ? "Weekly" : "Daily";
};

export const getThisWeek = () => {
    const curDayToInWeek = dayjs().day();

    if (curDayToInWeek < 3) {
        return dayjs().weekday(6).subtract(3, "week").format("YYYY-MM-DD");
    } else {
        return dayjs().weekday(6).subtract(2, "week").format("YYYY-MM-DD");
    }
};

export const getPayrollPeriodString = (periodStartDate) => {
    if (!periodStartDate) return '';
    return `${dayjs(periodStartDate).format("MM/DD/YYYY")} - ${dayjs(periodStartDate)
        .add(6, "d")
        .format("MM/DD/YYYY")}`
};

export const formatDate = (date, withYear = true, daysToAdd = 0) => {
    const dayjsDate = dayjs(date);

    let format = 'MMM DD';

    if (withYear) {
        format = 'MMM DD, YYYY';
    }

    if (daysToAdd > 0) {
        return dayjsDate.add(daysToAdd, 'd').format(format);
    }

    return dayjsDate.format(format);
};

export const initiateFileDownloadFromBlob = (blob, fileName) => {
    // Create a URL for the Blob object.
    const fileURL = window.URL.createObjectURL(blob);

    // Get the extension file
    const extension = blob.type.split('/')[1];

    // Create a temporary download link element.
    const downloadLink = document?.createElement('a');
    downloadLink.href = fileURL;
    downloadLink.setAttribute('download', `${fileName}.${extension}`); // Set the download file name.

    // Append the link to the document and trigger the download.
    document?.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up by removing the link and revoking the created URL.
    document?.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(fileURL);
};

export const isUserSourceRestricted = () => {
    const userData = JSON.parse(localStorage.getItem('user'));

    const restrictedSources = ['package_route'];

    if (userData && userData.organization) {
        return restrictedSources.includes(userData.organization.source);
    }

    return false;
};

export const getUserSource = () => {
    const userData = JSON.parse(localStorage.getItem('user'));

    if (userData && userData.organization) {
        return userData.organization.source;
    }

    return '';
};

export const isUserPackageRoute = () => {
    return getUserSource() === "package_route";
};

export const defaultSettingPage = () => {
    // companies for user source restricted
    if (isUserSourceRestricted()) {
        return "companies";
    }

    // default page for other users
    return "profile";
};

export const redirectUserSourceRestricted = (currentPage, setPage, nav) => {
    const restrictedPages = ['profile', 'subscription', 'users'];
    if (isUserSourceRestricted() && restrictedPages.includes(currentPage)) {
        setPage('companies');

        nav({
            pathname: "/settings",
            search: `?page=companies`
        });
    }
};

export const calculatePercentageOfTotal = (partValue, totalValue) => {
    return ((partValue && totalValue) ? (partValue / totalValue) * 100 : 0).toFixed(2);
};

export const transformBytesInToKilobytes = (bytes = 0) => {
    return (bytes / 1024).toFixed(1);
};
