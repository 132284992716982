import "../../assets/css/modal.css"
import { Checkbox, Modal } from "@mui/material"
import { useState } from "react"
import { ReactComponent as DeleteIcon } from "../../assets/images/run-payroll/delete-filled.svg"
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg"
import Loading from "../common/Loading"
import Button from "../UI/Button/Button"

const ConfirmDeleteModal = ({
  open,
  handleClose,
  handleConfirm,
  text,
  confirmButtonText,
  icon,
  isLoading,
  subtitle,
  checkbox,
  noIcon,
  noCloseIcon
}) => {
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = (e) => setIsChecked(e.target.checked)

  const handleCurClose = () => {
    handleClose()
    setIsChecked(false)
  }

  return (
    <Modal open={open} onClose={handleCurClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper">
          {!noCloseIcon && (
            <div className="confirm-modal-close-wrapper" onClick={handleCurClose}>
              <CloseIcon />
            </div>
          )}

          {!noIcon && (
            <div className="confirm-modal-icon-wrapper">
              {icon || <DeleteIcon className="confirm-modal-icon" />}
            </div>
          )}

          {text}

          {subtitle && <div className="confirm-modal-subtitle">{subtitle}</div>}

          {checkbox && (
            <div className="form-item mt1">
              <div className="delete-confirm-checkbox-container">
                <Checkbox
                  className="paychex-checkbox"
                  checked={isChecked}
                  name="checkbox"
                  onChange={handleCheckboxChange}
                />
                <label>Delete employees</label>
              </div>
            </div>
          )}

          <div className="confirm-modal-btn-wrapper confirm-modal-button-wrapper">
            <Button onClick={handleCurClose} type="grey-bg" text="Cancel" className="half-width" />
            <Button
              onClick={handleConfirm.bind(null, isChecked, setIsChecked)}
              type="red-bg"
              className="half-width"
              disabled={isLoading}
              text={isLoading ? <Loading /> : confirmButtonText || "Delete"}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmDeleteModal
