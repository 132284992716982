import React from "react"
import PayrollItem from "./PayrollItem"
import * as dayjs from "dayjs"
import { useSelector } from "react-redux"
import { getUser } from "../../store/auth/auth.selectors"

const PayrollList = ({ payrolls, handleSortChange, activeSortedItem, handleUpdateList, productPrice }) => {
  const userData = useSelector(getUser)

  const isSubscribedKeyExisting = Object.keys(userData).includes("is_subscribed")
  const isCompanySubscribed = userData.is_subscribed
  const isPastDue = userData.is_past_due
  const trialDaysDiff = Math.ceil(dayjs(userData.subscription_ends_at).diff(dayjs(), "hours") / 24)
  const isTrialEnded = userData.is_trial_expired || trialDaysDiff < 1

  const canUploadNewPayroll =
    isSubscribedKeyExisting &&
    !isPastDue &&
    (isCompanySubscribed || (!isCompanySubscribed && !isTrialEnded))

  return (
    <div className="run-payroll-content">
      {payrolls.map((el, index) => (
        <PayrollItem
          key={el.id}
          itemData={el}
          index={index}
          handleUpdateList={handleUpdateList}
          canUploadNewPayroll={canUploadNewPayroll}
          productPrice={productPrice}
        />
      ))}

      {/*<RunPayrollTable*/}
      {/*  payrolls={payrolls}*/}
      {/*  displayBy={displayBy}*/}
      {/*  handleSortChange={handleSortChange}*/}
      {/*  activeSortedItem={activeSortedItem}*/}
      {/*/>*/}
    </div>
  )
}

export default PayrollList
