import React from "react"
import { ReactComponent as ErrorIcon } from "../../assets/images/run-payroll/error-icon.svg"

const RunPayrollStepError = ({ title, description }) => (
  <div className="step-error">
    <ErrorIcon />
    <div className="text-container">
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </div>
  </div>
)

export default RunPayrollStepError
