import { Avatar, MenuItem, Select, Tooltip } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useState } from "react";

import axios from "../../axios";
import EditBig from "../../assets/images/settings/edit-big.png";
import { ReactComponent as GustoIcon } from "../../assets/images/employees/is_gusto.svg";
import PaychexLogo from "../../assets/images/home/paychex-logo.png";
import toastService from "../../services/toastService";

const EmployeeFormHeader = ({
  setAvatar,
  setIsPayHistory,
  editedUserId,
  setIsEditComplete,
  setIsActive,
  isActive,
  editedUser,
  isGusto,
  isPaychex,
  avatar,
  saveLoading,
  first_name,
  last_name
}) => {
  const [activeLoading, setActiveLoading] = useState(false);

  const handleAvatarChange = (e) => {
    if (e.target?.files?.[0] && e?.currentTarget?.files?.[0]) {
      const formData = new FormData();
      formData.append("file", e.currentTarget.files[0]);

      setAvatar({ file: formData, url: URL.createObjectURL(e.target.files[0]) });
    }
  };

  const handlePayHistoryOpen = () => setIsPayHistory && setIsPayHistory(true);

  const handleActiveChange = () => {
    setActiveLoading(true);

    axios
      .put(`/employee/${editedUserId}/toggle-is-active`)
      .then((response) => {
        if (response.success) {
          setIsEditComplete && setIsEditComplete(true);

          setIsActive(!isActive);

          const successMessage = editedUser?.paychex_company_integrations.length
            ? "Employee successfully edited. Please, manually change the Status on Paychex Flex"
            : "Employee successfully edited";

          toastService.success(successMessage);
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setActiveLoading(false));
  };
  return (
    <div className="profile-sec employees-popup-profile-sec">
      {isGusto && (
        <Tooltip
          arrow
          title={
            <div className="employees-popup-profile-tooltip">
              Any changes you make will be automatically synchronized with the Gusto platform
            </div>
          }>
          <div className="employees-popup-profile-gusto">
            <GustoIcon />
          </div>
        </Tooltip>
      )}
      {isPaychex && (
        <>
          <Tooltip
            arrow
            title={<div className="employees-popup-profile-tooltip">Connected to Paychex</div>}>
            <div className="employees-popup-profile-paychex">
              <img src={PaychexLogo} />
            </div>
          </Tooltip>
        </>
      )}
      <div className="employees-modal-header">
        <div className="profile-img profile-info employees-modal-header">
          <Avatar
            variant="circular"
            className="employees-profile-img"
            alt={editedUser?.first_name}
            src={avatar?.url || editedUser?.profile_image}
          />
          {!saveLoading && (
            <button className="employees-profile-img-upload profile-info">
              <img src={EditBig} alt="" />
              <input
                disabled={!isActive}
                type="file"
                id="file-uploader"
                accept="image/png, image/jpeg"
                onChange={handleAvatarChange}
              />
            </button>
          )}
        </div>

        {editedUserId && (
          <div className="employees-modal-header-active">
            <div className="employees-modal-header-user-name">
              <p>{first_name}</p> <p>{last_name}</p>
            </div>
            <Select
              className="employees-active-select"
              name="active_state"
              value={isActive}
              renderValue={(value) => {
                return (
                  <div className="employees-active-select-container">
                    <div
                      className="employees-active-select-indicator"
                      style={{ background: value ? "#16AB6D" : "#FC5371" }}
                    />
                    {value ? "Active" : "Inactive"}
                  </div>
                );
              }}
              onChange={handleActiveChange}
              IconComponent={KeyboardArrowDown}
              disabled={activeLoading}>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </div>
        )}
      </div>

      <div className="employee-modal-header-buttons">
        {editedUserId && (
          <button onClick={handlePayHistoryOpen} className="btn-style upload-fuel-btn pay-history">
            Pay History
          </button>
        )}
      </div>
    </div>
  );
};

export default EmployeeFormHeader;
