import React, { useEffect, useState } from "react"
import { ReactComponent as Check } from "../../assets/images/settings/step-check.svg"
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/run-payroll/arrow-left.svg"
import RunPayrollDropzone from "./RunPayrollDropzone"
import HelpAccordion from "../../components/common/HelpAccordion/HelpAccordion"
import SelectField from "../common/SelectField"
import RunPayrollStepError from "./RunPayrollStepError"
import RunPayrollStepFile from "./RunPayrollStepFile"
import stepperData from "./steps-content"
import axios from "../../axios"
import toastService from "../../services/toastService"
import { useNavigate, useParams } from "react-router-dom"
import Loading from "../common/Loading"
import MissingEmployeeModal from "../modals/MissingEmployeeModal"
import AddMissingEmployeeModal from "../modals/AddMissingEmployeeModal"
import MatchEmployeeModal from "../modals/MatchEmployeeModal"
import TimeCardUpload from "./TimeCardUpload"
import { isUserPackageRoute, isUserSourceRestricted } from "../../helpers/helpers"
import {actions} from "../../store/auth/auth.reducers";

const STEP_CS = 1 // Charge Statement
const STEP_WSW = 2 // Weekly Service Worksheet
const STEP_TIMECARD = 3 // Time Card
const STEP_FUEL = 4 // FUEL

const getFileTypeByStep = (step) => {
  switch (step) {
    case STEP_CS:
      return "statements"
    case STEP_WSW:
      return "dsw"
    case STEP_TIMECARD:
      return "time-card"
    case STEP_FUEL:
      return "fuel"
    default:
      return null
  }
}

const getNameByType = (type) => {
  switch (type) {
    case "statements":
      return "Charge Statements"
    case "dsw":
      return "Weekly Service Worksheet"
    case "time-card":
      return "Time Card"
    case "fuel":
      return "Fuel"
    default:
      return null
  }
}

// content steps-content.js
const RunPayrollStep = ({
  content,
  payroll,
  lastStep,
  handleBack,
  handleNext,
  step,
  completedSteps = [],
  handleAddCompletedStep,
  activeStep,
  allFiles,
  handleUpdateAllFiles
}) => {
  const nav = useNavigate()
  const { payrollId } = useParams()
  const [isFilesLoading, setIsFilesLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMatchEmployeeModal, setIsLoadingMatchEmployeeModal] = useState(false)
  const [selectValue, setSelectValue] = useState(" ")
  const [showFilesMissingError, setShowFilesMissingError] = useState(false)
  const [selectError, setSelectError] = useState(false)
  const [isShowSkipBtn, setIsShowSkipBtn] = useState(true)
  const [showDateRangeMismatchError, setShowDateRangeMismatchError] = useState(null)
  const [filesFormData, setFilesFormData] = useState([])
  const [filesTimeCard, setFilesTimeCard] = useState({})

  const [needAddEmployeeList, setNeedAddEmployeeList] = useState([])
  const [isNeedAddEmployee, setIsNeedAddEmployee] = useState(false)
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false)

  // Need to replace this to elements from api
  const [needMatchEmployeeList, setNeedMatchEmployeeList] = useState([])
  const [isNeedMatchEmployee, setIsNeedMatchEmployee] = useState(false)

  const isCSStep = step === STEP_CS // Charge Statement
  const isWSWStep = step === STEP_WSW // Weekly Service Worksheet
  const isTimeCardStep = step === STEP_TIMECARD
  const isFuelStep = step === STEP_FUEL

  const isUserRestricted = isUserSourceRestricted()

  const [processedFilesToBeDeleted, setProcessedFilesToBeDeleted] = useState([]);

  useEffect(() => {
    const isPayrollPartiallyProcessed = payroll?.statements?.length > 0

    if (lastStep || (isWSWStep && !isPayrollPartiallyProcessed)) {
      setIsShowSkipBtn(false)
    }
  }, [step])

  useEffect(() => {
    let type = getFileTypeByStep(step)
    let files = []

    if (type === "time-card") {
      const result = allFiles[type].reduce((acc, file) => {
        const companyID = file.name.match(/^(\d+)_/)?.[1]
        if (companyID) {
          acc[companyID] = { file: { ...file }, isProcessed: true }
        }
        return acc
      }, {})
      setFilesTimeCard(result)
    } else {
      allFiles[type]?.map((file) => {
        files = [
          ...files,
          {
            name: file.name,
            size: file.size,
            type: file.extension,
            isProcessed: true
          }
        ]
      })

      setFilesFormData([...files])
    }

    // select type for timecard
    if (payroll && isTimeCardStep) {
      // Package Route users
      if (isUserRestricted && isUserPackageRoute()) {
        setSelectValue("package-route");
      } else {
        setSelectValue(payroll.timecard_type);
      }
    }

    // select type for fuel
    if (payroll && isFuelStep) {
      setSelectValue(payroll.fuel_type)
    }
  }, [step, allFiles])

  useEffect(() => {
    setShowFilesMissingError(false)
    setShowDateRangeMismatchError(null)
  }, [activeStep])

  const handleSelectValueChange = (e) => {
    setSelectError(false)
    setSelectValue(e.target?.value)
  }

  const handleNextButtonClick = () => {
    const isPayrollPartiallyProcessed = payroll?.statements?.length > 0

    if (!filesFormData.length && isWSWStep && !isPayrollPartiallyProcessed) {
      setShowFilesMissingError(true)
      return
    }

    const filesCount = filesFormData.filter((file) => !file.isProcessed).length
    const filteredTimeCardItemsCount = Object.values(filesTimeCard).filter(
      (item) => !item.isProcessed
    ).length

    // if we have any new files or delete processed files - process
    if (filesCount || filteredTimeCardItemsCount || processedFilesToBeDeleted.length > 0) {
      let type = getFileTypeByStep(step)
      processFilesData(type)
      return
    }

    lastStep ? nav(`/run-payroll-preview/${payrollId}`) : handleNext()
  }

  const getFilesFormData = (params = {}) => {
    const formData = new FormData()

    if (processedFilesToBeDeleted.length > 0) {
        formData.append('delete_files', JSON.stringify(processedFilesToBeDeleted))
    }

    Object.keys(params).forEach((key) => {
      const value = params[key]

      if (value !== undefined && value !== null) {
        if (typeof value === "object") {
          formData.append(key, JSON.stringify(value))
        } else {
          formData.append(key, value)
        }
      }
    })

    // paste select value for fuel & time-card
    if (isFuelStep || isTimeCardStep) {
      formData.append("type", selectValue)
    }

    if (isFuelStep) {
      formData.append("file", filesFormData[0])
      return formData
    }

    if (isTimeCardStep) {
      const filteredTimeCardItems = Object.fromEntries(
        Object.entries(filesTimeCard).filter(([key, value]) => !value.isProcessed)
      )
      for (const [key, value] of Object.entries(filteredTimeCardItems)) {
        formData.append(`${key}`, value.file)
      }
    } else {
      // we have to upload only unprocessed?
      let files = filesFormData.filter((file) => !file.isProcessed) // why is Processed is false here even it's true

      for (let i = 0; i < files.length; i++) {
        if (!files[i].isProcessed) {
          // ignore processed files
          formData.append("files[]", files[i])
        }
      }
    }

    return formData
  }

  // const handleUploadTimeClick = (e) => {
  //   e.preventDefault();
  //   setIsUploadingTimeFile(true);
  //
  //   const formData = new FormData();
  //
  //   formData.append("type", uploadingTimeOpenType);
  //   for (const [key, value] of Object.entries(files)) {
  //     formData.append(`${key}`, value);
  //   }
  //
  //   axios
  //   .post(`/payroll/${payrollId}/upload-time-card`, formData)
  //   .then((response) => {
  //     fetchPayrollData();
  //     fetchUserData();
  //     curHandleClose();
  //
  //     if(response.success){
  //       toastService.success(response.message)
  //     }
  //   })
  //   .catch((error) => {
  //     const message = error?.response?.data?.message || "Something went wrong";
  //     toastService.error(message)
  //   })
  //   .finally(() => setIsUploadingTimeFile(false));
  // };

  const processFilesData = (type) => {
    if (!type) return toastService.error("Something went wrong.. Please Contact Development Team.")

    if ((isFuelStep || isTimeCardStep) && !selectValue) {
      const missedType = isFuelStep ? "Fuel" : "Time Card"
      setSelectError(true)
      return toastService.error(`Please, Select ${missedType}`)
    }

    setIsLoading(true)
    axios
      .post(`/payroll/${payrollId}/files/${type}`, getFilesFormData())
      .then((res) => {
        if (isWSWStep) {
          const employeeMatches = res?.data?.employee_matches

          if (employeeMatches) {
            const filteredEmployeeMatches = employeeMatches?.reduce((acc, el) => {
              if (!el.matched) {
                return [el, ...acc]
              }
              return [...acc, el]
            }, [])

            setNeedMatchEmployeeList(filteredEmployeeMatches)
          }
          setIsNeedMatchEmployee(true)

          return
        }

        handleUpdateAllFiles()

        if (isFuelStep) {
          toastService.success(res?.message)
        } else {
          toastService.success(getNameByType(type) + " Successfully Uploaded")
        }

        isFuelStep ? nav(`/run-payroll-preview/${payrollId}`) : handleNext()
      })
      .catch((err) => {
        const responseData = err?.response?.data
        const errorMessage = responseData?.message

        const errorEmployeeMissed =
          (errorMessage?.includes("Missing Employees") ||
            err?.message?.includes("Missing Employees")) &&
          responseData?.data

        if (errorEmployeeMissed) {
          setIsNeedAddEmployee(true)
          setNeedAddEmployeeList(
            Object.entries(responseData.data).reduce((acc, el) => {
              return [...acc, { ...el[1], fedex_id: el[0] }]
            }, [])
          )
          return
        }

        if (err.response?.status === 422) {
          const fileError = errorMessage?.includes("The correct date range")
          if (fileError) {
            setShowDateRangeMismatchError(errorMessage)
          } else {
            toastService.error(errorMessage)
          }
          // Delete local upload files
          const filesFormDataWithoutLocal = filesFormData.filter((el) => !el.lastModified)
          setFilesFormData(filesFormDataWithoutLocal)
        } else {
          toastService.error(errorMessage || "Something went wrong")
        }
      })
      .finally(() => {
        setProcessedFilesToBeDeleted([]);
        setIsLoading(false)
      })
  }

  const handleDeleteTimeCard = (id) => {
    const filesCopy = { ...filesTimeCard }
    delete filesCopy[id]

    setFilesTimeCard(filesCopy)
  }

  const handleUploadTimeCard = (id, e) => {
    const file = e.currentTarget.files[0]

    const adjustedFileName = `${id}_${file.name}` // Example: add a prefix to the file name

    const adjustedFile = new File([file], adjustedFileName, { type: file.type })

    setFilesTimeCard({
      ...filesTimeCard,
      [id]: {
        file: adjustedFile,
        isProcessed: false
      }
    })
  }

  const handleUploadFile = (e) => {
    const files = Array.from(e.currentTarget.files)

    const filteredFiles = files.filter(
      (file) =>
        !filesFormData.some((existingFile) => {
          return existingFile.name === file.name
        })
    )

    let finalFiles = []

    for (let i = 0; i < filteredFiles.length; i++) {
      let file = filteredFiles[i]
      file.isProcessed = false
      finalFiles = [...finalFiles, file]
    }

    setFilesFormData((prev) => [...prev, ...finalFiles])
    setShowFilesMissingError(false)
    setShowDateRangeMismatchError(null)
  }

  const handleDeleteFile = (deletingFileData) => {
    const newFilesArr = [...filesFormData].filter((el) => el.name !== deletingFileData.name)
    setFilesFormData(newFilesArr)
  }

  const canDeleteFileProcessed = (file) => {
    if (file.isProcessed) {
      return isFuelStep;
    }

    return false;
  }

  const handleDeleteFileProcessed = (deletingFileData) => {
    setProcessedFilesToBeDeleted((prev) => [...prev, deletingFileData.name]);
    setFilesFormData((prev) => prev.filter((file) => file.name !== deletingFileData.name));
  };

  const handleIsNeedAddEmployeeClose = () => {
    setIsNeedAddEmployee(false)
    setNeedAddEmployeeList([])
  }

  const handleAddEmployeeOpenClose = () => {
    setAddEmployeeOpen(false)
    setNeedAddEmployeeList([])
  }

  const handleConfirm = () => {
    setIsNeedAddEmployee(false)
    setAddEmployeeOpen(true)
  }

  const handleMatchEmployeeConfirm = () => {
    const params = {
      matches: needMatchEmployeeList
    }

    setIsLoadingMatchEmployeeModal(true)

    axios
      .post(`/payroll/${payrollId}/process-with-matches/dsw`, getFilesFormData(params))
      .then((res) => {
        handleIsNeedMatchEmployeeClose()
        toastService.success(getNameByType("dsw") + " Successfully Uploaded")
        handleNext()
        handleUpdateAllFiles()
      })
      .catch((err) => {
        const responseData = err?.response?.data
        const errorMessage = responseData?.message

        toastService.error(errorMessage || "Something went wrong")
      })
      .finally(() => {
        setIsLoadingMatchEmployeeModal(false)
      })
  }

  const handleIsNeedMatchEmployeeClose = () => {
    setNeedMatchEmployeeList([])
    setIsNeedMatchEmployee(false)
  }

  const getFilesContainerMaxHeight = () => {
    switch (step) {
      case 1:
        return "medium"
      case 2:
        return "small"
      case 3:
        return "big"
      case 4:
        return "big"

      default:
        return "medium"
    }
  }

  return (
    <div className="step-content">
      <div className="title">{content.title}</div>
      <div className="description">{content.description}</div>
      {content.benefits && (
        <div className="benefits-container">
          <div className="title">Benefits Overview</div>

          <div className="benefit-overview-container">
            <div className="benefits-overview">
              {content.benefits.map((item) => (
                <div className="benefit-item" key={item}>
                  <Check />
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {content.select && (
        <SelectField
          options={content.select.options}
          name={content.select.name}
          title={content.select.name}
          isError={selectError}
          fullWidth
          value={
            isTimeCardStep && isUserRestricted && isUserPackageRoute()
              ? "package-route"
              : selectValue
          }
          optionValueName="value"
          onChange={handleSelectValueChange}
          label="testong"
          placeholder={content.select.placeholder}
        />
      )}

      {isTimeCardStep ? (
        <TimeCardUpload
          statements={payroll?.statements}
          handleUploadFile={handleUploadTimeCard}
          files={filesTimeCard}
          handleDeleteFile={handleDeleteTimeCard}
        />
      ) : (
        <>
          <RunPayrollDropzone
            uploadText={content.uploadText}
            handleUploadFile={handleUploadFile}
            fileFormats={content.fileFormats}
          />
          {isFilesLoading ? (
            <div>
              <Loading />
            </div>
          ) : (
            !![...filesFormData].length && (
              <div className={`step-content-files-container ${getFilesContainerMaxHeight()}`}>
                {[...filesFormData].map((el) => (
                  <RunPayrollStepFile
                    key={el.name || el.lastModified}
                    fileData={el}
                    handleDeleteFile={handleDeleteFile}
                    maxHeightClassName={getFilesContainerMaxHeight()}
                    canDeleteFileProcessed={canDeleteFileProcessed(el)}
                    handleDeleteFileProcessed={handleDeleteFileProcessed}
                  />
                ))}
              </div>
            )
          )}
        </>
      )}

      {showFilesMissingError && (
        <RunPayrollStepError
          title={stepperData.filesMissing.title}
          description={stepperData.filesMissing.description}
        />
      )}

      {showDateRangeMismatchError && (
        <RunPayrollStepError title="Date Range Mismatch" description={showDateRangeMismatchError} />
      )}

      {content.help ? <HelpAccordion data={content.help} /> : null}

      <button className="next-button" disabled={isLoading} onClick={handleNextButtonClick}>
        {isLoading ? <Loading /> : lastStep ? "Complete" : "Next"}
      </button>
      <div className="back-skip-buttons">
        {!isCSStep ? (
          <button onClick={handleBack}>
            <ArrowLeftIcon />
            Back
          </button>
        ) : (
          <div></div>
        )}

        {isShowSkipBtn && (
          <button className="skip" onClick={handleNext}>
            Skip <ArrowLeftIcon />
          </button>
        )}
      </div>

      <MatchEmployeeModal
        open={isNeedMatchEmployee}
        handleClose={handleIsNeedMatchEmployeeClose}
        handleConfirm={handleMatchEmployeeConfirm}
        needMatchEmployeeList={needMatchEmployeeList}
        setNeedMatchEmployeeList={setNeedMatchEmployeeList}
        isLoading={isLoadingMatchEmployeeModal}
      />

      <MissingEmployeeModal
        open={isNeedAddEmployee}
        handleClose={handleIsNeedAddEmployeeClose}
        handleConfirm={handleConfirm}
        needAddEmployeeList={needAddEmployeeList}
      />

      <AddMissingEmployeeModal
        open={addEmployeeOpen}
        handleClose={handleAddEmployeeOpenClose}
        needAddEmployeeList={needAddEmployeeList}
      />
    </div>
  )
}

export default RunPayrollStep
