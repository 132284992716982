import "../../assets/css/modal.css";
import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import { ReactComponent as AddMoreIcon } from "../../assets/images/settings/add-more.svg";
import Loading from "./../common/Loading";
import DeleteIcon from "../../assets/images/run-payroll/delete-filled.svg";
import { isUserSourceRestricted } from "../../helpers/helpers";
import InputField from "../common/InputField";

const schema = yup.object().shape({
  work_area_number: yup.string().required("Work Area is required!")
});

const WorkAreaModal = ({ open, handleClose, handleConfirm, editedWorkArea, isLoading }) => {
  const [customErrors, setCustomErrors] = useState({});

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, touchedFields }
  } = useForm({
    mode: "all",
    defaultValues: {
      add_per_package: 0,
      add_per_stop: 0,
      auto_clock_in: 1,
      clock_in_time: 30,
      clock_out_time: 15,
      deleted_stop_bonuses: [],
      deleted_package_bonuses: [],
      add_stop_bonus: 0,
      add_package_bonus: 0
    },
    resolver: yupResolver(schema)
  });

  const {
    add_per_package,
    add_per_stop,
    stop_bonuses,
    package_bonuses,
    deleted_package_bonuses,
    deleted_stop_bonuses,
    add_stop_bonus,
    add_package_bonus,
    auto_clock_in,
    clock_in_time,
    clock_out_time
  } = watch();

  const [stopBonusesArray, setStopBonusesArray] = useState(
    stop_bonuses?.length > 0 ? stop_bonuses : [{ id: 0, per_item: "stop" }]
  );
  const [packageBonusesArray, setPackageBonusesArray] = useState(
    package_bonuses?.length > 0 ? package_bonuses : [{ id: 0, per_item: "package" }]
  );

  const isUserRestricted = isUserSourceRestricted();

  useEffect(() => {
    if (open && editedWorkArea) {
      setValue("work_area_number", editedWorkArea.work_area_number, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("clock_in_time", editedWorkArea.rates.clock_in_time, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("clock_out_time", editedWorkArea.rates.clock_out_time, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("auto_clock_in", editedWorkArea.rates.auto_clock_in, {
        shouldValidate: true,
        shouldDirty: true
      });
      if (editedWorkArea.bonuses) {
        const stop_bonuses = editedWorkArea.bonuses.filter((el) => el.per_item === "stop");
        const package_bonuses = editedWorkArea.bonuses.filter((el) => el.per_item === "package");
        setValue("stop_bonuses", stop_bonuses, {
          shouldValidate: true,
          shouldDirty: true
        });
        setValue("package_bonuses", package_bonuses, {
          shouldValidate: true,
          shouldDirty: true
        });

        if (stop_bonuses.length > 0) {
          setValue("add_per_stop", 1, {
            shouldValidate: true,
            shouldDirty: true
          });
          setStopBonusesArray(stop_bonuses);
        }

        if (package_bonuses.length > 0) {
          setValue("add_per_package", 1, {
            shouldValidate: true,
            shouldDirty: true
          });
          setPackageBonusesArray(package_bonuses);
        }
      }
      setValue("daily_pay", editedWorkArea.rates.daily_pay, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("hourly_rate", editedWorkArea.rates.hourly_rate, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("add_per_stop", editedWorkArea.rates.add_per_stop, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("add_per_package", editedWorkArea.rates.add_per_package, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("add_stop_bonus", editedWorkArea.rates.add_stop_bonus, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("add_package_bonus", editedWorkArea.rates.add_package_bonus, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("stop_delivery_rate", editedWorkArea.rates.stop_delivery_rate, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("stop_ecommerce_rate", editedWorkArea.rates.stop_ecommerce_rate, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("stop_pickup_rate", editedWorkArea.rates.stop_pickup_rate, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("package_delivery_rate", editedWorkArea.rates.package_delivery_rate, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("package_ecommerce_rate", editedWorkArea.rates.package_ecommerce_rate, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("package_pickup_rate", editedWorkArea.rates.package_pickup_rate, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [open]);

  const handleMainClose = () => {
    handleClose();
    reset();
    setStopBonusesArray([{ id: 0, per_item: "stop" }]);
    setPackageBonusesArray([{ id: 0, per_item: "package" }]);
  };

  const onSubmit = (values) => handleConfirm(values, handleMainClose);

  const handlePerStopChange = () =>
    setValue("add_per_stop", add_per_stop === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    });

  const handleNumberChange = (inputName, e) => {
    const value = e.target.value
      .replace(/[^\d.]/g, "")
      .replace(/\.([.\d]+)$/, function (m, m1) {
        return "." + m1.replace(/\./g, "");
      })
      .replace(/^0*([^0]\d*\.\d{1,2}).*/g, "$1");
    setValue(inputName, value, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handlePerPackageChange = () =>
    setValue("add_per_package", add_per_package === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    });

  const handlePackageBonusChange = () => {
    setPackageBonusesArray(
      package_bonuses?.length > 1 ? package_bonuses : [{ id: 0, per_item: "package" }]
    );
    setValue("package_bonuses", package_bonuses, {
      shouldValidate: true,
      shouldDirty: true
    });
    setValue("add_package_bonus", add_package_bonus === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handleStopBonusChange = () => {
    setStopBonusesArray(stop_bonuses?.length > 1 ? stop_bonuses : [{ id: 0, per_item: "stop" }]);
    setValue("stop_bonuses", stop_bonuses, {
      shouldValidate: true,
      shouldDirty: true
    });
    setValue("add_stop_bonus", add_stop_bonus === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handlePackageThresholdChange = (index, e) => {
    const value = e.target?.value.replace(/[^\d.]/g, "").replace(/\.([.\d]+)$/, function (m, m1) {
      return "." + m1.replace(/\./g, "");
    });

    if (package_bonuses?.length > 1) {
      const arrayOfMatches = package_bonuses
        .map((el) => {
          if (
            el.threshold &&
            +el.threshold > 0 &&
            +e.target?.value > 0 &&
            +el.threshold === +e.target?.value
          ) {
            return true;
          }
          return false;
        })
        .filter((el) => el === true);

      if (arrayOfMatches.length > 0) {
        const errorsData = {
          ...errors,
          [`package_bonuses_${index}_threshold`]: "Daily threshold must be unique"
        };
        setCustomErrors(errorsData);
      } else {
        const newErrors = { ...errors };
        delete newErrors[`package_bonuses_${index}_threshold`];
        setCustomErrors(newErrors);
      }
    }

    setValue(`package_bonuses[${index}][threshold]`, value, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handleStopThresholdChange = (index, e) => {
    const value = e.target?.value.replace(/[^\d.]/g, "").replace(/\.([.\d]+)$/, function (m, m1) {
      return "." + m1.replace(/\./g, "");
    });

    if (stop_bonuses?.length > 1) {
      const arrayOfMatches = stop_bonuses
        .map((el) => {
          if (
            el.threshold &&
            +el.threshold > 0 &&
            +e.target?.value > 0 &&
            +el.threshold === +e.target?.value
          ) {
            return true;
          }
          return false;
        })
        .filter((el) => el === true);

      if (arrayOfMatches.length > 0) {
        const errorsData = {
          ...errors,
          [`stop_bonuses_${index}_threshold`]: "Daily threshold must be unique"
        };
        setCustomErrors(errorsData);
      } else {
        const newErrors = { ...errors };
        delete newErrors[`stop_bonuses_${index}_threshold`];
        setCustomErrors(newErrors);
      }
    }

    setValue(`stop_bonuses[${index}][threshold]`, value, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handleStopDeleteBonus = (index, e) => {
    e.preventDefault();
    const item = stop_bonuses.find((el, indexN) => indexN === index);
    const newBonusesArr = [...stopBonusesArray];
    newBonusesArr.splice(index, 1);
    setStopBonusesArray(newBonusesArr);
    setValue(
      "stop_bonuses",
      stop_bonuses.filter((el, indexN) => indexN !== index),
      {
        shouldValidate: true,
        shouldDirty: true
      }
    );

    if (item.id > 0) {
      setValue("deleted_stop_bonuses", [...deleted_stop_bonuses, item.id]);
    }
  };

  const handlePackageDeleteBonus = (index, e) => {
    e.preventDefault();
    const item = package_bonuses.find((el, indexN) => indexN === index);
    const newBonusesArr = [...packageBonusesArray];
    newBonusesArr.splice(index, 1);
    setPackageBonusesArray(newBonusesArr);
    setValue(
      "package_bonuses",
      package_bonuses.filter((el, indexN) => indexN !== index),
      {
        shouldValidate: true,
        shouldDirty: true
      }
    );

    if (item.id > 0) {
      setValue("deleted_package_bonuses", [...deleted_package_bonuses, item.id]);
    }
  };

  const handlePackageAddMore = () => {
    setPackageBonusesArray([
      ...packageBonusesArray,
      {
        price: null,
        threshold: null,
        id: packageBonusesArray.length + 1,
        per_item: "package"
      }
    ]);
  };

  const handleStopAddMore = () => {
    setStopBonusesArray([
      ...stopBonusesArray,
      {
        price: null,
        threshold: null,
        id: stopBonusesArray.length + 1,
        per_item: "stop"
      }
    ]);
  };

  const handleAutoClockInChange = () =>
    setValue("auto_clock_in", auto_clock_in === 0 ? 1 : 0, {
      shouldValidate: true,
      shouldDirty: true
    });

  return (
    <Modal open={open} onClose={handleMainClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper add-member-modal">
          <div className="add-member-modal-header">
            <div className="title-sec">
              <h2>{editedWorkArea ? "Edit" : "Add"} Work Area</h2>
            </div>

            <div className="confirm-modal-close-wrapper" onClick={handleMainClose}>
              <CloseIcon />
            </div>
          </div>

          <form className="profile-form-sec add-member-modal-form add-work-area">
            <div className="employee-form-half-item-container mb-3">
              <InputField
                title="Work Area *"
                placeholder=" "
                name="work_area_number"
                errorMessage={errors.work_area_number?.message}
                isError={!!errors.work_area_number?.message}
                isTouched={touchedFields.work_area_number}
                otherProps={register("work_area_number")}
                fullWidth
                tooltipText=""
                type="number"
                disabled={editedWorkArea && isUserRestricted}
              />
            </div>
            <div className="add-work-area-rate">
              <div className="add-work-area-rate-container">
                <h6>Rate</h6>

                <div className="form-items-container full-width">
                  <InputField
                    title="Daily Rate"
                    placeholder=" "
                    name="daily_pay"
                    errorMessage={errors.daily_pay?.message}
                    isError={!!errors.daily_pay?.message}
                    isTouched={touchedFields.daily_pay}
                    otherProps={register("daily_pay")}
                    onChange={handleNumberChange.bind(null, "daily_pay")}
                    onFocus={(event) => event.target.select()}
                    fullWidth
                    spanText="$"
                    tooltipText="Set a flat daily pay amount, regardless of hours or stops"
                  />

                  <InputField
                    title="Hourly Rate"
                    placeholder=" "
                    name="hourly_rate"
                    errorMessage={errors.hourly_rate?.message}
                    isError={!!errors.hourly_rate?.message}
                    isTouched={touchedFields.hourly_rate}
                    otherProps={register("hourly_rate")}
                    onChange={handleNumberChange.bind(null, "hourly_rate")}
                    onFocus={(event) => event.target.select()}
                    fullWidth
                    spanText="$"
                    tooltipText="Enter the standard hourly pay rate"
                  />
                </div>
              </div>

              {/*<div className="add-work-area-vertical-divider" />*/}

              {/*<div className="auto-clock-in-container">*/}
              {/*  <div className="option-toggle add-bonus-toggle" onClick={handleAutoClockInChange}>*/}
              {/*    <span className={`toggle-btn ${auto_clock_in === 1 ? "active" : ""}`}></span>*/}
              {/*    <span>Auto Clock In/Out</span>*/}
              {/*  </div>*/}
              {/*  {auto_clock_in === 1 && (*/}
              {/*    <div className="auto-clock-in-selects-container">*/}
              {/*      <div className="form-item clock-in-time">*/}
              {/*        <label>Clock in Add</label>*/}
              {/*        <div className="input-box">*/}
              {/*          <Select*/}
              {/*            className="employees-select"*/}
              {/*            name="clock_in_time"*/}
              {/*            value={clock_in_time}*/}
              {/*            {...register("clock_in_time")}*/}
              {/*            IconComponent={KeyboardArrowDown}>*/}
              {/*            {[0, 15, 30, 45, 60]?.map((el) => (*/}
              {/*              <MenuItem key={el} value={el}>*/}
              {/*                {el}*/}
              {/*              </MenuItem>*/}
              {/*            ))}*/}
              {/*          </Select>*/}
              {/*        </div>*/}
              {/*      </div>*/}

              {/*      <div className="clock-in-time-divider" />*/}

              {/*      <div className="form-item clock-in-time">*/}
              {/*        <label>Clock Оut Add</label>*/}
              {/*        <div className="input-box">*/}
              {/*          <Select*/}
              {/*            className="employees-select"*/}
              {/*            name="clock_out_time"*/}
              {/*            value={clock_out_time}*/}
              {/*            {...register("clock_out_time")}*/}
              {/*            IconComponent={KeyboardArrowDown}>*/}
              {/*            {[0, 15, 30, 45, 60]?.map((el) => (*/}
              {/*              <MenuItem key={el} value={el}>*/}
              {/*                {el}*/}
              {/*              </MenuItem>*/}
              {/*            ))}*/}
              {/*          </Select>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*</div>*/}
            </div>

            <div className="add-bonus-sec">
              <div className="option-toggle add-bonus-toggle" onClick={handlePerStopChange}>
                <span className={`toggle-btn ${add_per_stop ? "active" : ""}`}></span>
                <span>Add Per Stop </span>
              </div>
              <span className="employee-more-info mt-0 flex">
                Pay an additional rate for each completed stop
              </span>

              {Boolean(add_per_stop) && (
                <div className="add-bonus-field pb-1">
                  <div className="three-column">
                    <InputField
                      title="Pickup"
                      placeholder="0.00"
                      name="stop_pickup_rate"
                      errorMessage={errors.stop_pickup_rate?.message}
                      isError={!!errors.stop_pickup_rate?.message}
                      isTouched={touchedFields.stop_pickup_rate}
                      otherProps={register("stop_pickup_rate")}
                      onChange={handleNumberChange.bind(null, "stop_pickup_rate")}
                      onFocus={(event) => event.target.select()}
                      fullWidth
                      spanText="$"
                    />
                    <InputField
                      title="Delivery"
                      placeholder="0.00"
                      name="stop_delivery_rate"
                      errorMessage={errors.stop_delivery_rate?.message}
                      isError={!!errors.stop_delivery_rate?.message}
                      isTouched={touchedFields.stop_delivery_rate}
                      otherProps={register("stop_delivery_rate")}
                      onChange={handleNumberChange.bind(null, "stop_delivery_rate")}
                      onFocus={(event) => event.target.select()}
                      fullWidth
                      spanText="$"
                    />
                    <InputField
                      title="E-commerce"
                      placeholder="0.00"
                      name="stop_ecommerce_rate"
                      errorMessage={errors.stop_ecommerce_rate?.message}
                      isError={!!errors.stop_ecommerce_rate?.message}
                      isTouched={touchedFields.stop_ecommerce_rate}
                      otherProps={register("stop_ecommerce_rate")}
                      onChange={handleNumberChange.bind(null, "stop_ecommerce_rate")}
                      onFocus={(event) => event.target.select()}
                      fullWidth
                      spanText="$"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="add-work-area-divider" />

            <div className="add-bonus-sec">
              <div className="option-toggle add-bonus-toggle" onClick={handlePerPackageChange}>
                <span className={`toggle-btn ${add_per_package ? "active" : ""}`}></span>
                <span>Add Per Package </span>
              </div>
              <span className="employee-more-info mt-0 flex">
                Pay an additional rate for each delivered package
              </span>

              {Boolean(add_per_package) && (
                <div className="add-bonus-field pb-1">
                  <div className="three-column">
                    <InputField
                      title="Pickup"
                      placeholder="0.00"
                      name="package_pickup_rate"
                      errorMessage={errors.package_pickup_rate?.message}
                      isError={!!errors.package_pickup_rate?.message}
                      isTouched={touchedFields.package_pickup_rate}
                      otherProps={register("package_pickup_rate")}
                      onChange={handleNumberChange.bind(null, "package_pickup_rate")}
                      onFocus={(event) => event.target.select()}
                      fullWidth
                      spanText="$"
                    />
                    <InputField
                      title="Delivery"
                      placeholder="0.00"
                      name="package_delivery_rate"
                      errorMessage={errors.package_delivery_rate?.message}
                      isError={!!errors.package_delivery_rate?.message}
                      isTouched={touchedFields.package_delivery_rate}
                      otherProps={register("package_delivery_rate")}
                      onChange={handleNumberChange.bind(null, "package_delivery_rate")}
                      onFocus={(event) => event.target.select()}
                      fullWidth
                      spanText="$"
                    />
                    <InputField
                      title="E-commerce"
                      placeholder="0.00"
                      name="package_ecommerce_rate"
                      errorMessage={errors.package_ecommerce_rate?.message}
                      isError={!!errors.package_ecommerce_rate?.message}
                      isTouched={touchedFields.package_ecommerce_rate}
                      otherProps={register("package_ecommerce_rate")}
                      onChange={handleNumberChange.bind(null, "package_ecommerce_rate")}
                      onFocus={(event) => event.target.select()}
                      fullWidth
                      spanText="$"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="add-work-area-divider" />

            <div className="add-bonus-sec">
              <div className="option-toggle add-bonus-toggle" onClick={handleStopBonusChange}>
                <span className={`toggle-btn ${add_stop_bonus ? "active" : ""}`}></span>
                <span>Add Stop Threshold</span>
              </div>
              <span className="employee-more-info mt-0 flex">
                Enable a bonus once a certain number of stops have been completed
              </span>

              {Boolean(add_stop_bonus) && (
                <div className="add-bonus-field bonus pb-1">
                  {stopBonusesArray.map((el, index) => (
                    <div className="form-item-container mb-2" key={index}>
                      <InputField
                        title="Stop Threshold"
                        placeholder="100"
                        name={`stop_bonuses[${index}][threshold]`}
                        errorMessage={customErrors[`stop_bonuses_${index}_threshold`]}
                        isError={!!customErrors[`stop_bonuses_${index}_threshold`]}
                        isTouched={touchedFields.stop_bonuses?.[index]?.threshold}
                        otherProps={register(`stop_bonuses[${index}][threshold]`)}
                        onChange={handleStopThresholdChange.bind(null, index)}
                        fullWidth
                      />
                      <InputField
                        title="Per Stop"
                        placeholder="1.00"
                        name={`stop_bonuses[${index}][price]`}
                        errorMessage={errors.price?.message}
                        isError={!!errors.price?.message}
                        isTouched={touchedFields.price}
                        otherProps={register(`stop_bonuses[${index}][price]`)}
                        onChange={handleNumberChange.bind(null, `stop_bonuses[${index}][price]`)}
                        onFocus={(event) => event.target.select()}
                        fullWidth
                        spanText="$"
                      />
                      <button
                        className="document-more-btn menu-item"
                        onClick={handleStopDeleteBonus.bind(null, index)}>
                        <img src={DeleteIcon} alt="" />
                      </button>
                    </div>
                  ))}

                  {stopBonusesArray.length < 3 && (
                    <div
                      className="bonus-add-more add-work-area-add-more"
                      onClick={handleStopAddMore}>
                      <AddMoreIcon /> Add more
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="add-work-area-divider" />

            <div className="add-bonus-sec">
              <div className="option-toggle add-bonus-toggle" onClick={handlePackageBonusChange}>
                <span className={`toggle-btn ${add_package_bonus ? "active" : ""}`}></span>
                <span>Add Package Threshold</span>
              </div>
              <span className="employee-more-info mt-0 flex">
                Enable a bonus once a certain number of packages have been delivered
              </span>

              {Boolean(add_package_bonus) && (
                <div className="add-bonus-field bonus pb-1">
                  {packageBonusesArray.map((el, index) => (
                    <div className="form-item-container mb-2" key={index}>
                      <InputField
                        title="Package Threshold"
                        placeholder="100"
                        name={`package_bonuses[${index}][threshold]`}
                        errorMessage={customErrors[`package_bonuses_${index}_threshold`]}
                        isError={!!customErrors[`package_bonuses_${index}_threshold`]}
                        isTouched={touchedFields.package_bonuses?.[index]?.threshold}
                        otherProps={register(`package_bonuses[${index}][threshold]`)}
                        onChange={handlePackageThresholdChange.bind(null, index)}
                        fullWidth
                      />
                      <InputField
                        title="Per Package"
                        placeholder="1.00"
                        name={`package_bonuses[${index}][price]`}
                        errorMessage={errors.price?.message}
                        isError={!!errors.price?.message}
                        isTouched={touchedFields.price}
                        otherProps={register(`package_bonuses[${index}][price]`)}
                        onChange={handleNumberChange.bind(null, `package_bonuses[${index}][price]`)}
                        onFocus={(event) => event.target.select()}
                        fullWidth
                        spanText="$"
                      />
                      <button
                        className="document-more-btn menu-item"
                        onClick={handlePackageDeleteBonus.bind(null, index)}>
                        <img src={DeleteIcon} alt="" />
                      </button>
                    </div>
                  ))}

                  {packageBonusesArray.length < 3 && (
                    <div
                      className="bonus-add-more add-work-area-add-more"
                      onClick={handlePackageAddMore}>
                      <AddMoreIcon /> Add more
                    </div>
                  )}
                </div>
              )}
            </div>
          </form>

          <div className="add-member-form-btn-wrapper">
            <button
              className="btn-style blank-btn confirm-modal-btn company-btn add-popup-cancel"
              onClick={handleMainClose}>
              Cancel
            </button>
            <button
              className="btn-style confirm-modal-btn recalculate company-btn"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading || Object.keys(customErrors).length}>
              {isLoading ? <Loading /> : editedWorkArea ? "Save" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WorkAreaModal;
