import { styled, Tooltip, tooltipClasses } from "@mui/material";

const DarkTooltip = styled(({ className, styles = {}, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ styles }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#333645",
    color: "#FFFFFF",
    fontSize: 13,
    borderRadius: "8px",
    fontWeight: 400,
    fontFamily: "Gilroy",
    padding: "4px 8px",
    marginRight: 15,
    maxWidth: "400px",
    textAlign: "center",
    [`& .MuiTooltip-arrow`]: {
      color: "#333645"
    },
    ...styles
  }
}));

export default DarkTooltip;
