import toast from 'react-hot-toast';
import Toast from '../components/shared/Toast';

const formatNotificationMessage = (message) => {
  // Replace raw </br> tags with React line breaks or spaces
  return message.replace(/<\/br>/g, "\n");
};

const toastService = {
  success: (message, description) => {
    toast.success((t) => <Toast title={formatNotificationMessage(message)} description={description} />, {
      duration: 3000 // 3s
    });
  },
  error: (message, description) => {
    toast.error((t) => <Toast title={formatNotificationMessage(message)} description={description} />); // 4s
  },
  errorHandler: (error) => {
    const errorMessage = formatNotificationMessage(error.response?.data?.message || error.message)
    toast.error((t) => <Toast title={errorMessage} />);// 4s
  },
  info: (message, description) => {
    toast((t) => <Toast title={formatNotificationMessage(message)} description={description} />);
  },
  warning: (message, description) => {
    toast((t) => <Toast title={formatNotificationMessage(message)} description={description} />, {
      icon: "⚠️",
    });
  },
  promise: (promise, messages, onSuccess) => {
    return toast.promise(
      promise,
      {
        loading: messages.loading,
        success: (data) => {
          if (onSuccess) onSuccess(data); // Optional callback after success
          return <Toast title={messages.success(data)} />;
        },
        error: (error) => messages.error(error),
      }
    );
  },
};

export default toastService;
