import React from 'react';
import { toast } from 'react-hot-toast';
// import {
//   ReactComponent as CloseIcon
// } from '../../assets/images/close-icon.svg';
// import { styled } from '@mui/material/styles';

// const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
//   width: 8,
//   height: 8,
//   '& path': {
//     fill: 'white'
//   }
// }));

const Toast = ({ title, text = null }) => {
  const closeToast = () => toast.dismiss();
  return (
    <div style={{display: 'flex'}}>
      <h3 style={{ fontSize: '15px', color: '#fff', marginBottom: 0,  }}>
        {title}
      </h3>
      {text ? (
        <p style={{ margin: '8px 0 0', fontSize: '14px', color: '#ccc' }}>
          {text}
        </p>
      ): null}
      {/*<button*/}
      {/*  onClick={closeToast}*/}
      {/*  style={{*/}
      {/*    width: '20px',*/}
      {/*    height: '20px',*/}
      {/*    position: 'absolute',*/}
      {/*    top: '14px',*/}
      {/*    fontWeight: 300,*/}
      {/*    color: 'white',*/}
      {/*    right: '16px',*/}
      {/*    background: 'none',*/}
      {/*    border: '1px solid white',*/}
      {/*    borderRadius: '50px',*/}
      {/*    fontSize: '14px',*/}
      {/*    cursor: 'pointer'*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <StyledCloseIcon />*/}
      {/*</button>*/}
    </div>
  );
};

export default Toast;
