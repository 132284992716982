import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EmployeesContainer from "../components/employees/EmployeesContainer";
import TitleInfo from "./../components/common/TitleInfo";
import EmployeesModal from "../components/modals/EmployeesModal";
import { getEmployees } from "../store/employee/employee.selectors";
import { ReactComponent as Import } from "../assets/images/employees/import.svg";
import { ReactComponent as Export } from "../assets/images/employees/export.svg";
import axios from "../axios";
import { initiateFileDownloadFromBlob, isUserSourceRestricted } from "../helpers/helpers";
import { useMediaQuery } from "@mui/material";
import toastService from "../services/toastService";

const Employees = () => {
  const isMobile = useMediaQuery("(max-width:990px)");

  const [open, setOpen] = useState(false);
  const [isAddComplete, setIsAddComplete] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [isPaychexIntegrations, setIsPaychexIntegrations] = useState(false);
  const [paychexIntegrationsLoading, setPaychexIntegrationsLoading] = useState(false);

  const employees = useSelector(getEmployees);

  const isUserRestricted = isUserSourceRestricted(); // PR

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleImport = (e) => {
    setIsImportLoading(true);

    const formData = new FormData();
    formData.append("file", e.currentTarget.files[0]);

    axios
      .post("employee/import", formData)
      .then((response) => {
        setIsAddComplete(true);

        if(response.success){
          toastService.success(response.message)
        }
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || "Something went wrong")
      )
      .finally(() => setIsImportLoading(false));
  };

  const handleExport = () => {
    axios({
      method: "get",
      url: `/employee/download-csv`,
      responseType: "blob"
    })
      .then((response) => {
        const filename =
          process.env.REACT_APP_API_HOST !== "https://api.fleetwage.com/api"
            ? "test-employees"
            : "employees";
        initiateFileDownloadFromBlob(response, filename);
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || "Something went wrong")
      );
  };

  useEffect(() => {
    setPaychexIntegrationsLoading(true);

    axios
      .get("/integrations/paychex")
      .then((response) => {
        setIsPaychexIntegrations(
          response?.data?.some((integration) => integration.data.approved) || false
        );
      })
      .catch((err) =>
        toastService.error(err.response?.data?.message || "Something went wrong")
      )
      .finally(() => {
        setPaychexIntegrationsLoading(false);
      });
  }, []);

  return (
    <>
      <TitleInfo
        title="Employees"
        subTitle="Filter and update employee records"
        buttons={[
          {
            name: "Import",
            class: "btn-style upload-fuel-btn employees-profile-img-upload employees-btn",
            icon: isMobile ? null : <Import />,
            loading: isImportLoading,
            input: (
              <input
                type="file"
                id="file-uploader"
                accept=".csv"
                disabled={isImportLoading}
                onChange={handleImport}
              />
            )
          },
          {
            name: "Export",
            class: "btn-style upload-fuel-btn employees-btn",
            onClick: handleExport,
            icon: isMobile ? null : <Export />
          },
          {
            name: "+ Add Employee",
            class: "btn-style add-employee-btn loading-style",
            onClick: handleOpen,
            disabled: isPaychexIntegrations,
            loading: paychexIntegrationsLoading,
            tooltipText: isPaychexIntegrations
              ? "Adding new employees is disabled due to direct integration with Paychex."
              : ""
          }
        ]}
      />

      <EmployeesContainer isAddComplete={isAddComplete} setIsAddComplete={setIsAddComplete} />

      <EmployeesModal
        open={open}
        handleClose={handleClose}
        setIsEditComplete={employees.length < 11 ? setIsAddComplete : undefined}
      />
    </>
  );
};

export default Employees;
