import { memo, useEffect, useRef, useState } from "react"
import { NumericFormat } from "react-number-format"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { KeyboardArrowUp, KeyboardArrowDown, MoreVert } from "@mui/icons-material"
import { styled } from "@mui/material/styles"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import dayjs from "dayjs"
import { calculateTotalPayAndPerStop, formatMoney, formatNumber } from "../../utils"
import Avatar from "../common/Avatar"
import { useDebouncedEffect } from "../../hooks/useDebouncedEffect"
import { actions } from "../../store/payroll-preview/payroll-preview.reducers"
import axios from "../../axios"
import { ReactComponent as InfoCircle } from "../../assets/images/run-payroll-preview/info-circle.svg"
import { ReactComponent as ArrowDown } from "../../assets/images/settings/arrow-down.svg"
import { getStatementAllData } from "../../store/payroll-preview/payroll-preview.selectors"
import Loading from "./../common/Loading"
import EmptyState from "./../common/EmptyState"
import RunPayrollStatementMoreTableRow from "./RunPayrollStatementMoreTableRow"
import BlurText from "../common/BlurText"
import DarkTooltip from "../common/DarkToolTip"
import toastService from "../../services/toastService"
import { Menu, MenuItem } from "@mui/material"
import DeleteIcon from "../../assets/images/run-payroll/delete-filled.svg"
import EmailReportIcon from "../../assets/images/report/email.svg"
import MessageReportIcon from "../../assets/images/report/message.svg"
import NoteIcon from "../../assets/images/note.svg"
import EmployeeTextReportModal from "../modals/EmployeeTextReportModal"
import EmployeeEmailReportModal from "../modals/EmployeeEmailReportModal"
import EmployeeAddDateModal from "../modals/EmployeeAddDateModal"
import CalendarAddIcon from "../../assets/images/run-payroll-preview/calendar-add.svg"

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#1D1858",
    boxShadow: "0px 0px 24px 0px #0000001A",
    fontSize: 12,
    borderRadius: "3px",
    fontWeight: 400,
    padding: 0,
    maxWidth: "400px",
    [`& .MuiTooltip-arrow`]: {
      color: theme.palette.common.white
    }
  }
}))

const RunPayrollStatementTableRow = ({
  itemData,
  handleClick,
  handleDeleteEmployee,
  stationMinimumWage,
  isAllOpen,
  allMoreDataLoading,
  allItemMoreData,
  fetchStatementList,
  employeeIdFocus
}) => {
  const dispatch = useDispatch()
  const { payrollId, statementId } = useParams()
  const employeeRefs = useRef({}) // Store refs for each employee
  const statementAllData = useSelector(getStatementAllData)

  const {
    total_w2,
    total_fuel,
    total_stops,
    total_packages,
    working_days,
    employee,
    pto,
    total_hours,
    addition = 0,
    totalPay,
    report_rates,
    deduction,
    perStop,
    profit,
    profit_before_changes,
    training_days,
    worked_in_multiple_stations,
    warnings,
    is_hours_disabled,
    total_wa_hourly,
    added_bonus,
    added_deduction_bonus,
    total_ils,
    total_code_85,
    total_all_status_code_package,
    total_dna,
    total_miss_pickup_window,
    total_early_late_pickup,
    total_w2_before_changes,
    six_day_bonus,
    sunday_bonus
  } = itemData

  const rates = employee?.rates || {}

  const [isFirstRender, setIsFirstRender] = useState(true)
  const [bonus, setBonus] = useState(+addition ? addition : "0.00")
  const [deductionBonus, setDeductionBonus] = useState(+deduction ? deduction : "0.00")
  const [newPto, setNewPto] = useState(pto)
  const [newTrainingDays, setNewTrainingDays] = useState(training_days)
  const [totalHours, setTotalHours] = useState(total_hours)
  const [totalFuel, setTotalFuel] = useState(total_fuel)

  const [isOpen, setIsOpen] = useState(false)
  const [moreDataLoading, setMoreDataLoading] = useState(false)
  const [itemMoreData, setItemMoreData] = useState([])
  const [moreStationsLoading, setMoreStationsLoading] = useState(false)
  const [itemMoreStationsData, setItemMoreStationsData] = useState([])

  const isWeekly = rates?.weekly_rate_w2
  const isNotNativeCompany = statementAllData.station_id !== employee.station?.csa

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [notes, setNotes] = useState(itemData.notes)
  const [lastNotes, setLastNotes] = useState(notes)
  const [showNotes, setShowNotes] = useState(!!notes)

  const [employeeTextReportOpen, setEmployeeTextReportOpen] = useState(false)
  const [employeeEmailReportOpen, setEmployeeEmailReportOpen] = useState(false)

  const [dailySalaries, setDailySalaries] = useState({})

  const [employeeAddDateOpen, setEmployeeAddDateOpen] = useState(false)

  useEffect(() => {
    if (employeeIdFocus && employeeRefs.current[employeeIdFocus]) {
      // console.log(employeeIdFocus)
      const element = employeeRefs.current[employeeIdFocus]
      element.focus()
      element.scrollIntoView({
        behavior: "smooth", // Smooth scrolling
        block: "center" // Center the element in the viewport
      })

      // Add a blinking class
      element.classList.add("highlight")
      // Remove the class after 1 second
      setTimeout(() => {
        element.classList.remove("highlight")
      }, 30000)
    }
  }, [employeeIdFocus])

  const handleEmployeeTextReportOpen = () => {
    handleActionClose()
    setEmployeeTextReportOpen(true)
  }

  const handleEmployeeTextReportClose = () => setEmployeeTextReportOpen(false)

  const handleEmployeeEmailReportOpen = () => {
    handleActionClose()
    setEmployeeEmailReportOpen(true)
  }

  const handleEmployeeEmailReportClose = () => setEmployeeEmailReportOpen(false)

  const handleEmployeeAddDateOpen = () => {
    handleActionClose()
    setEmployeeAddDateOpen(true)
  }

  const handleEmployeeAddDateClose = () => setEmployeeAddDateOpen(false)

  useEffect(() => {
    if (!isFirstRender && (!isWeekly || (isWeekly && rates?.adding_daily_pay !== 0))) {
      dispatch(actions.addAlertToExpenses())
    }
  }, [bonus, newPto, totalHours, deductionBonus])

  useDebouncedEffect(
    () => {
      if (isFirstRender) {
        setIsFirstRender(false)
        return
      }

      const { elTotalPay: totalPayroll, perStop: newPerStop } = calculateTotalPayAndPerStop(
        {
          addition: bonus || 0,
          deduction: deductionBonus || 0,
          pto: newPto || 0,
          employee,
          total_hours: totalHours || 0,
          total_w2: total_w2 || 0,
          total_stops: total_stops || 0,
          total_fuel: totalFuel || 0,
          training_days: newTrainingDays || 0,
          is_hours_disabled,
          total_wa_hourly: total_wa_hourly
        },
        statementAllData.station_id
      )

      const body = {
        deduction: deductionBonus || 0,
        addition: bonus || 0,
        pto: newPto,
        total_hours: totalHours || 0,
        total_fuel: totalFuel || 0,
        training_days: newTrainingDays
      }

      axios
        .put(`/statement/${statementId}/weekly/${itemData.id}`, body)
        .catch(() => toastService.error("Something went wrong"))

      dispatch(
        actions.changeStatementEmployeeTotalPay({
          totalPay: totalPayroll || 0,
          id: itemData.id,
          totalFuel: totalFuel || 0,
          bonus: +bonus || 0,
          deduction: +deductionBonus || 0,
          profit: profit_before_changes - (totalFuel || 0) - (totalPayroll - total_w2),
          newPto,
          newTrainingDays,
          totalHours: totalHours || 0,
          station_id: statementAllData.station_id,
          perStop: newPerStop
        })
      )
    },
    [bonus, newPto, totalHours, totalFuel, deductionBonus, newTrainingDays],
    500
  )

  const fetchItemAllData = () => {
    setMoreDataLoading(true)

    axios
      .get(`/statement/${statementAllData.id}/employee/${employee.id}/daily-summary`)
      .then((response) => {
        if (response.success) {
          setItemMoreData(response.data?.employee?.daily_summary)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setMoreDataLoading(false))
  }

  const fetchMoreStationsData = () => {
    setMoreStationsLoading(true)

    axios
      .get(`/statement/${statementId}/multiple-companies/${employee.id}`)
      .then((response) => {
        if (response.success) {
          setItemMoreStationsData(response.data)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setMoreStationsLoading(false))
  }

  useEffect(() => {
    if (isOpen && !isAllOpen) {
      fetchItemAllData()
      worked_in_multiple_stations && fetchMoreStationsData()
    } else if (!isOpen && itemMoreData) {
      setItemMoreData([])
      setItemMoreStationsData([])
    }
  }, [isOpen])

  useEffect(() => {
    setIsOpen(isAllOpen)
  }, [isAllOpen])

  const handleBonusChange = (v) => setBonus(v)

  const handleDeductionBonusChange = (v) => setDeductionBonus(v)

  const handleIncrease = () => {
    if (!rates?.paid_time_off || isNotNativeCompany) {
      return
    }
    if (rates?.paid_time_off && newPto < 7 && working_days + newTrainingDays + newPto < 7) {
      setNewPto(newPto + 1)
    } else {
      toastService.error("Employee can’t work more than 7 days/week")
    }
  }

  const handleDecrease = () => {
    if (!rates?.paid_time_off || isNotNativeCompany) {
      return
    }
    if (rates?.paid_time_off && newPto > 0) {
      setNewPto(newPto - 1)
    }
  }

  const handleTrainingDaysIncrease = () => {
    if (working_days + newTrainingDays + newPto < 7) {
      setNewTrainingDays(newTrainingDays + 1)
    } else {
      toastService.error("Employee can’t work more than 7 days/week")
    }
  }

  const handleTrainingDaysDecrease = () => {
    if (newTrainingDays > 0) {
      setNewTrainingDays(newTrainingDays - 1)
    }
  }

  const handleTotalHoursChange = (v) => setTotalHours(v)
  const handleTotalFuelChange = (v) => setTotalFuel(v)

  const handleOpenChange = () => setIsOpen(!isOpen)

  const isErrorTotalPay =
    (totalHours > 0 && totalHours * stationMinimumWage > totalPay) ||
    (working_days > 0 && totalPay === 0)

  const isNoHover = ![1, "1", true, "true"].includes(employee.active) || isErrorTotalPay

  const isOvertime = rates?.is_overtime_enabled && +totalHours > 40

  const calculatedHour = rates?.subtracting_daily_pay
    ? 0
    : rates?.is_work_area_enabled === 1
      ? total_wa_hourly
      : isOvertime
        ? 40 * rates.hourly_rate + (+totalHours - 40) * (1.5 * rates.hourly_rate)
        : totalHours * +rates.hourly_rate

  const curPayValue = total_w2 + calculatedHour

  const setItemMoreDataFromAll = () => {
    allItemMoreData?.daily_summary?.length &&
      !itemMoreData.length &&
      setItemMoreData(allItemMoreData.daily_summary)
    allItemMoreData?.multiple_companies?.length &&
      !itemMoreStationsData.length &&
      setItemMoreStationsData(allItemMoreData.multiple_companies)
  }

  useEffect(() => {
    if (allItemMoreData && isAllOpen && isOpen) {
      setItemMoreDataFromAll()
    }
  }, [allItemMoreData, isOpen])

  const getBonusAmount = (name, fallbackAmount) => {
    const bonus = Array.isArray(report_rates) ? report_rates.find((b) => b.name === name) : null
    return bonus ? parseFloat(fallbackAmount || 0) : 0
  }

  const incentivesBonus = (added_bonus = 0, six_day_bonus = 0, sunday_bonus = 0) => {
    const sixthDayBonus = getBonusAmount("6th Day Bonus", six_day_bonus)
    const sundayBonus = getBonusAmount("Sunday Bonus", sunday_bonus)
    return (sixthDayBonus + sundayBonus + parseFloat(added_bonus)).toFixed(2)
  }

  const handleActionOpen = (event) => setAnchorEl(event.currentTarget)

  const handleActionClose = () => setAnchorEl(null)

  const handleAddNotes = () => {
    setShowNotes(true)
    handleActionClose()
    setIsOpen(true)
  }

  const handleSetNotes = async () => {
    if (notes?.trim() && notes !== lastNotes) {
      axios
        .post(`/employee-weekly-summary/${itemData?.id}/notes`, {
          notes
        })
        .then(() => {
          setLastNotes(notes)
        })
        .catch((err) => toastService.error(err.response?.data?.message || err.message))
    }
  }

  const handleKeyDownSetNotes = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
      handleSetNotes()
    }
  }

  const handleDelete = () => {
    handleActionClose()
    handleDeleteEmployee(itemData)
  }

  // const handleEmployeeDetail = () => {
  //   handleActionClose()
  //   handleClick(employee)
  // }

  const handleDailyHoursChange = (dailySummary, hours) => {
    axios
      .put(`/employee-daily-summary/${dailySummary?.id}`, {
        hours
      })
      .then((response) => {
        setTotalHours(response?.data?.total_weekly_hours)

        setDailySalaries((prev) => ({
          ...prev,
          [dailySummary?.id]: response?.data?.daily_summary?.salary
        }))
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }

  const canEmployeeAddDay = () => {
    const hasFreeDays = Object.keys(itemData?.free_days || {}).length > 0
    const isUnderLimit = working_days + newTrainingDays + newPto < 7

    return hasFreeDays && isUnderLimit
  }

  return (
    <>
      <tr
        ref={(el) => (employeeRefs.current[employee.id] = el)}
        style={{
          background: isOpen
            ? "#FCFCFC"
            : ![1, "1", true, "true"].includes(employee.active)
              ? "#F5F5F6"
              : isErrorTotalPay
                ? "#FFE8EA"
                : "#FFFFFF"
        }}>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          onClick={handleClick.bind(null, employee)}
          style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
          <div className="statement-first-block">
            <Avatar
              variant="circular"
              className="statement-avatar"
              alt={employee?.first_name}
              src={employee?.profile_image}
              withBadge
              badgeType={isWeekly ? "Weekly" : "Daily"}
              isMultipleIcon={worked_in_multiple_stations}
              warnings={warnings}
            />
            <div className="statement-name-block">
              <div className="statement-name-block-content">
                <div>
                  {employee?.first_name} <BlurText>{employee?.last_name}</BlurText>{" "}
                </div>
                <LightTooltip
                  title={
                    <div className="statement-name-block-tooltip">
                      <div className="title">
                        <p>{report_rates?.length > 0 ? "Pay Structure" : "No Pay Structure"}</p>
                      </div>
                      {report_rates?.length > 0
                        ? report_rates.map((el) => (
                            <div
                              key={el.name}
                              className={`statement-name-block-tooltip-item ${
                                el.name === "Threshold" ? "threshold" : ""
                              }`}>
                              {el.name}:{" "}
                              <b>
                                {el.amount}{" "}
                                {el.name === "Weekly W-2" &&
                                  employee.rates.adding_daily_pay === 0 &&
                                  "(CAP)"}
                              </b>
                            </div>
                          ))
                        : null}
                    </div>
                  }
                  arrow>
                  <InfoCircle className="statement-name-block-tooltip-icon" />
                </LightTooltip>
              </div>

              <div className="statement-company">{employee?.company_position_name}</div>
            </div>
          </div>
        </td>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          onClick={handleOpenChange}
          style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
          {working_days + newTrainingDays + newPto}
        </td>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          onClick={handleOpenChange}
          style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
          {formatMoney(perStop, true)}
        </td>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
          <div className="form-item statement-input fuel">
            <div className="input-box fuel">
              <NumericFormat
                value={totalFuel}
                onValueChange={(values) => handleTotalFuelChange(values.floatValue)}
                decimalScale={2}
                onFocus={(event) => event.target.select()}
                allowEmptyFormatting
                fixedDecimalScale
                prefix="$"
                thousandSeparator
                allowNegative={false}
                isAllowed={(values) => {
                  const { floatValue } = values
                  return floatValue < 10000 || [0, null, undefined].includes(floatValue)
                }}
              />
            </div>
          </div>
        </td>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          onClick={handleOpenChange}
          style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
          {formatNumber(total_stops)}
        </td>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          onClick={handleOpenChange}
          style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
          {formatNumber(total_packages)}
        </td>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          onClick={handleOpenChange}
          style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
          {formatMoney(curPayValue, true)}
        </td>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
          <div className="form-item statement-input">
            <div className="input-box">
              <NumericFormat
                value={bonus}
                onValueChange={(values) => handleBonusChange(values.floatValue)}
                decimalScale={2}
                onFocus={(event) => event.target.select()}
                allowEmptyFormatting
                fixedDecimalScale
                prefix="$"
                thousandSeparator
                allowNegative={false}
                isAllowed={(values) => {
                  const { floatValue } = values
                  return floatValue < 10000 || [0, null, undefined].includes(floatValue)
                }}
              />
            </div>
          </div>
        </td>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
          <div className="form-item statement-input">
            <div className="input-box">
              <NumericFormat
                value={deductionBonus}
                onValueChange={(values) => handleDeductionBonusChange(values.floatValue)}
                decimalScale={2}
                onFocus={(event) => event.target.select()}
                allowEmptyFormatting
                fixedDecimalScale
                prefix="$"
                thousandSeparator
                allowNegative={false}
                isAllowed={(values) => {
                  const { floatValue } = values
                  return floatValue < 10000 || [0, null, undefined].includes(floatValue)
                }}
              />
            </div>
          </div>
        </td>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
          <div className="form-item statement-input number">
            <div className="input-box">
              <NumericFormat
                value={newTrainingDays}
                type="number"
                allowNegative={false}
                isAllowed={(values) => {
                  const { floatValue } = values
                  return floatValue >= 0 && floatValue <= 7
                }}
              />
              <div className="statement-input-btns">
                <KeyboardArrowUp fontSize="small" onClick={handleTrainingDaysIncrease} />
                <KeyboardArrowDown fontSize="small" onClick={handleTrainingDaysDecrease} />
              </div>
            </div>
          </div>
        </td>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
          <div className="form-item statement-input number">
            <div className="input-box">
              <NumericFormat
                value={newPto}
                type="number"
                disabled={!rates?.paid_time_off || isNotNativeCompany}
                allowNegative={false}
                isAllowed={(values) => {
                  const { floatValue } = values
                  return floatValue >= 0 && floatValue <= 5
                }}
              />
              {!isNotNativeCompany && (
                <div className="statement-input-btns">
                  <KeyboardArrowUp fontSize="small" onClick={handleIncrease} />
                  <KeyboardArrowDown fontSize="small" onClick={handleDecrease} />
                </div>
              )}
            </div>
          </div>
        </td>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
          <div className="form-item statement-input hours">
            <div className="input-box">
              <NumericFormat
                value={totalHours}
                disabled={is_hours_disabled === 1}
                decimalScale={2}
                fixedDecimalScale
                onValueChange={(values) => handleTotalHoursChange(values.floatValue)}
                onFocus={(event) => event.target.select()}
                allowEmptyFormatting
                allowNegative={false}
                isAllowed={(values) => {
                  const { floatValue } = values
                  return (
                    (floatValue >= 0 && floatValue <= 168) ||
                    [0, null, undefined].includes(floatValue)
                  )
                }}
              />
            </div>
          </div>
        </td>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          onClick={handleOpenChange}
          style={{
            borderBottom: isOpen ? "none" : "1px solid #EEF0F2",
            fontWeight: 600,
            color: isErrorTotalPay ? "#FC5371" : "#1D1858"
          }}>
          {formatMoney(totalPay, true)}
        </td>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          onClick={handleOpenChange}
          style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
          {formatMoney(profit, true)}
        </td>
        <td
          style={{
            borderBottom: isOpen ? "none" : "1px solid #EEF0F2"
          }}>
          <button
            className="document-more-btn margin-auto"
            onClick={handleActionOpen}
            style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}>
            <MoreVert className="document-more-btn-icon" style={{ color: "#8D98A9" }} />
          </button>

          <Menu anchorEl={anchorEl} open={open} onClose={handleActionClose} className="more-menu">
            <MenuItem onClick={handleEmployeeEmailReportOpen}>
              <button className="document-more-btn menu-item">
                <img src={EmailReportIcon} alt="" />
              </button>
              Email Report
            </MenuItem>
            <MenuItem onClick={handleEmployeeTextReportOpen} disabled={true}>
              <button className="document-more-btn menu-item">
                <img src={MessageReportIcon} alt="" />
              </button>
              Text Report
            </MenuItem>
            {/*<MenuItem onClick={handleEmployeeDetail}>*/}
            {/*  <button className="document-more-btn menu-item">*/}
            {/*    <img src={UserIcon} alt=""/>*/}
            {/*  </button>*/}
            {/*  Employee Detail*/}
            {/*</MenuItem>*/}
            <MenuItem onClick={handleAddNotes}>
              <button className="document-more-btn menu-item">
                <img src={NoteIcon} alt="" />
              </button>
              Add Note
            </MenuItem>
            {canEmployeeAddDay() && (
              <MenuItem onClick={handleEmployeeAddDateOpen}>
                <button className="document-more-btn menu-item">
                  <img src={CalendarAddIcon} alt="" />
                </button>
                Add Date
              </MenuItem>
            )}
            <MenuItem onClick={handleDelete}>
              <button className="document-more-btn menu-item">
                <img src={DeleteIcon} alt="" />
              </button>
              Delete
            </MenuItem>
          </Menu>
        </td>
        <td
          className={isNoHover ? "no-hover-effect" : ""}
          style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
          <div className="statement-button-wrapper">
            <button
              onClick={handleOpenChange}
              className={`companies-btn edit ${isOpen ? "up" : ""}`}>
              <ArrowDown />
            </button>
          </div>
        </td>
      </tr>
      {isOpen && (
        <>
          {worked_in_multiple_stations &&
            (moreStationsLoading || allMoreDataLoading ? (
              <tr
                className="statement-more-data-container"
                style={{
                  borderLeft: "1px solid #EEF0F2",
                  borderRight: "1px solid #EEF0F2"
                }}>
                <td colSpan="16" className="statement-background">
                  <Loading />
                </td>
              </tr>
            ) : (
              itemMoreStationsData
                .filter((element) => element.statement_id !== +statementId)
                .map((elem) => (
                  <RunPayrollStatementMoreTableRow
                    key={elem.id}
                    itemData={elem}
                    employee={employee}
                  />
                ))
            ))}
          <tr className="statement-more-data-container">
            <td colSpan="16" className="statement-more-table">
              {moreDataLoading || allMoreDataLoading ? (
                <Loading />
              ) : itemMoreData?.length > 0 ? (
                <div className="statement-more-data-table">
                  <table>
                    <thead className="statement-more-data-table-header">
                      <tr>
                        <th>Date</th>
                        <th colSpan="2" style={{ fontWeight: 700 }}>
                          Total
                        </th>
                        <th colSpan="2" style={{ fontWeight: 700 }}>
                          Clock In/Out
                        </th>
                        <th colSpan="1" style={{ fontWeight: 700 }}>
                          Salary
                        </th>
                        <th colSpan="1" style={{ fontWeight: 700 }}>
                          Incentive
                        </th>
                        <th colSpan="1" style={{ fontWeight: 700 }}>
                          Reduction
                        </th>
                        <th colSpan="8" style={{ fontWeight: 700 }}>
                          DSW
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                        <td>Stop</td>
                        <td>Pkg</td>
                        <td>Hour</td>
                        <td>O/T</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>WA</td>
                        <td>ILS%</td>
                        <td>Code 85</td>
                        <td>Status Code</td>
                        <td>DNA</td>
                        <td>Miss PUs</td>
                        <td>E/L PUs</td>
                      </tr>
                      {itemMoreData.map((el) => (
                        <tr key={el.id}>
                          <td>{dayjs(el.date).format("MM/DD/YYYY - ddd")}</td>
                          <td>{el.total_stops}</td>
                          <td>{el.total_packages}</td>
                          <td
                            className={isNoHover ? "no-hover-effect" : ""}
                            style={{ borderBottom: isOpen ? "none" : "1px solid #EEF0F2" }}>
                            <div
                              className="form-item statement-input hours"
                              style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
                              <div className="input-box">
                                <DarkTooltip
                                  title={
                                    el.clock_in_time !== null || el.clock_out_time !== null ? (
                                      <>
                                        {el.clock_in_time !== null && (
                                          <div style={{ fontSize: "13px" }}>
                                            Start: {dayjs(el.clock_in_time).format("HH:mm")}
                                          </div>
                                        )}

                                        {el.clock_out_time !== null && (
                                          <div style={{ fontSize: "13px" }}>
                                            End: {dayjs(el.clock_out_time).format("HH:mm")}
                                          </div>
                                        )}
                                      </>
                                    ) : null
                                  }
                                  arrow
                                  placement="left">
                                  <div>
                                    <NumericFormat
                                      style={{ height: "30px", fontSize: "12px" }}
                                      value={(el?.hours ?? 0).toFixed(2)}
                                      decimalScale={2}
                                      fixedDecimalScale
                                      onValueChange={(values) => {
                                        const newValue = values.floatValue ?? 0
                                        handleDailyHoursChange(el, newValue)
                                      }}
                                      placeholder="0.00"
                                      onFocus={(event) => event.target.select()}
                                      allowEmptyFormatting
                                      allowNegative={false}
                                      isAllowed={(values) => {
                                        const { floatValue } = values
                                        return (
                                          (floatValue >= 0 && floatValue <= 168) ||
                                          [0, null, undefined].includes(floatValue)
                                        )
                                      }}
                                    />
                                  </div>
                                </DarkTooltip>
                              </div>
                            </div>
                          </td>
                          <td>-</td>
                          <td>
                            $
                            {dailySalaries[el.id] !== undefined
                              ? parseFloat(dailySalaries[el.id]).toFixed(2)
                              : el.salary}
                          </td>
                          <td>${el.added_bonus}</td>
                          <td>${el.added_deduction_bonus}</td>
                          <td>
                            {el.work_areas?.map((elem) => elem?.work_area_number).join(", ") || "-"}
                          </td>
                          <td>
                            {el.work_areas
                              ?.map((elem) => `${elem?.pivot?.ils_percentage}%`)
                              .join(", ") || "0%"}
                          </td>
                          <td>
                            {el.work_areas?.map((elem) => `${elem?.pivot?.code_85}`).join(", ") ||
                              "0"}
                          </td>
                          <td>
                            {el.work_areas
                              ?.map((elem) => `${elem?.pivot?.all_status_code_package}`)
                              .join(", ") || "0"}
                          </td>
                          <td>
                            {el.work_areas?.map((elem) => `${elem?.pivot?.dna}`).join(", ") || "0"}
                          </td>
                          <td>
                            {el.work_areas
                              ?.map((elem) => `${elem?.pivot?.miss_pickup_window}`)
                              .join(", ") || "0"}
                          </td>
                          <td>
                            {el.work_areas
                              ?.map((elem) => `${elem?.pivot?.early_late_pickup}`)
                              .join(", ") || "0"}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td style={{ fontWeight: 700 }}>TOTAL</td>
                        <td style={{ fontWeight: 700 }}>{total_stops}</td>
                        <td style={{ fontWeight: 700 }}>{total_packages}</td>
                        <td style={{ fontWeight: 700 }}>
                          {rates?.is_overtime_enabled
                            ? total_hours > 40
                              ? 40
                              : total_hours.toFixed(2)
                            : total_hours.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: 700 }}>
                          {rates?.is_overtime_enabled &&
                            (total_hours > 40 ? (total_hours - 40).toFixed(2) : 0)}
                        </td>
                        <td style={{ fontWeight: 700 }}>
                          ${(total_w2_before_changes + calculatedHour).toFixed(2)}
                        </td>
                        <td style={{ fontWeight: 700 }}>
                          ${incentivesBonus(added_bonus, six_day_bonus, sunday_bonus)}
                        </td>
                        <td style={{ fontWeight: 700 }}>${added_deduction_bonus}</td>
                        <td style={{ fontWeight: 700 }}>-</td>
                        <td style={{ fontWeight: 700 }}>{total_ils}%</td>
                        <td style={{ fontWeight: 700 }}>{total_code_85}</td>
                        <td style={{ fontWeight: 700 }}>{total_all_status_code_package}</td>
                        <td style={{ fontWeight: 700 }}>{total_dna}</td>
                        <td style={{ fontWeight: 700 }}>{total_miss_pickup_window}</td>
                        <td style={{ fontWeight: 700 }}>{total_early_late_pickup}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <EmptyState title="No Daily Summary" />
              )}
              {showNotes && (
                <div className="search-box note-container">
                  <input
                    type="text"
                    placeholder="Write a note..."
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    onBlur={handleSetNotes}
                    onKeyDown={handleKeyDownSetNotes}
                  />
                </div>
              )}
            </td>
          </tr>
        </>
      )}

      <EmployeeTextReportModal
        open={employeeTextReportOpen}
        handleClose={handleEmployeeTextReportClose}
        employee={employee}
        payrollId={payrollId}
      />
      <EmployeeEmailReportModal
        open={employeeEmailReportOpen}
        handleClose={handleEmployeeEmailReportClose}
        employee={employee}
        payrollId={payrollId}
      />
      {canEmployeeAddDay() && (
        <EmployeeAddDateModal
          open={employeeAddDateOpen}
          handleClose={handleEmployeeAddDateClose}
          employee={employee}
          statementId={statementId}
          free_days={itemData?.free_days}
          fetchStatementList={fetchStatementList}
        />
      )}
    </>
  )
}

export default memo(RunPayrollStatementTableRow)
