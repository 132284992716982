import { useEffect, useMemo, useState } from "react"
import dayjs from "dayjs"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { ReactComponent as BasicPlanIcon } from "../../../assets/images/settings/basic-plan.svg"
import { ReactComponent as ArrowUp } from "../../../assets/images/settings/arrow-up.svg"
import { ReactComponent as Check } from "../../../assets/images/settings/check.svg"
import { ReactComponent as CloseIcon } from "../../../assets/images/close-icon.svg"
import { getUser } from "../../../store/auth/auth.selectors"
import axios from "../../../axios"
import ConfirmDeleteModal from "../../modals/ConfirmDeleteModal"
import { actions } from "../../../store/auth/auth.reducers"
import { actions as dataActions } from "../../../store/data/data.reducers"
import { formatMoney } from "../../../utils"
import toastService from "../../../services/toastService"
import ActivateSubscriptionModal from "../../modals/ActivateSubscriptionModal"
// import SubscriptionAddPaymentMethodModal from "../../modals/SubscriptionAddPaymentMethodModal"
// import SubscriptionModal from "../../modals/SubscriptionModal"
// import { getCouponCode } from "../../../store/data/data.selectors"
// import { TextField } from "@mui/material"

const SubscriptionPlan = ({ setIsNeedRefresh, cards, productPrice, isLoading }) => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const userData = useSelector(getUser)
  // const couponCode = useSelector(getCouponCode)
  // const [isOpenFinishSubscription, setIsOpenFinishSubscription] = useState(false)
  // const [coupon, setCoupon] = useState("")
  // const [isApplyCouponLoading, setIsApplyCouponLoading] = useState(false)

  const [isCancelLoading, setIsCancelLoading] = useState(false)
  const [stationCount, setStationCount] = useState(0)
  const [isOpenAddSubscription, setIsOpenAddSubscription] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [newProductPrice, setNewProductPrice] = useState()

  const useQuery = () => {
    return useMemo(() => new URLSearchParams(search), [search])
  }
  const query = useQuery()
  const emailFromQuery = query.get("add_info_trial")

  useEffect(() => {
    fetchStationsCount()

    return () => dispatch(dataActions.setCouponCode(null))
  }, [])

  useEffect(() => {
    if (
      emailFromQuery === "true" &&
      !cards.length &&
      userData &&
      !userData.is_subscribed &&
      !isLoading
    ) {
      setIsOpenAddSubscription(true)
    }
  }, [cards, userData])

  useEffect(() => {
    axios
      .get("/subscription/subscription-price-with-discount")
      .then((response) => {
        const newPrice = response?.data?.price

        if (typeof newPrice === "number" && !isNaN(newPrice)) {
          setNewProductPrice(newPrice)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }, [])

  const handleConfirmDelete = () => {
    setIsCancelLoading(true)

    axios
      .post("/subscription/cancel-subscription")
      .then((response) => {
        if (response.success) {
          toastService.success(response.data.message)
          dispatch(actions.setUserIsSubscribed(false))
          setIsOpenDeleteModal(!isOpenDeleteModal)
          setIsNeedRefresh(true)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setIsCancelLoading(false))
  }

  const fetchStationsCount = () => {
    axios
      .get("/station-count")
      .then((response) => {
        if (response.success) {
          setStationCount(response.data)
        }
      })
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
  }

  const handleCloseAddSubscription = () => setIsOpenAddSubscription(false)
  const handleOpenModalAddSubscription = () => setIsOpenAddSubscription(true)
  // const handleCloseFinishSubscription = () => setIsOpenFinishSubscription(false)
  // const handleOpenFinishSubscription = () => setIsOpenFinishSubscription(true)

  const handleOpenAddSubscription = () => {
    handleOpenModalAddSubscription()
    // if (!cards.length) {
    //   handleOpenModalAddSubscription()
    // } else {
    //   handleOpenFinishSubscription()
    // }
  }

  const handleCancelOpen = () => setIsOpenDeleteModal(true)
  const handleCloseDeleteModal = () => setIsOpenDeleteModal(!isOpenDeleteModal)

  // const isCouponShow = !userData.is_subscribed

  // const handleCouponChange = (e) => setCoupon(e.target.value)
  // const handleApplyCoupon = () => {
  //   setIsApplyCouponLoading(true)

  //   axios
  //     .get(`coupons/apply/${coupon}`)
  //     .then((response) => {
  //       if (response.success) {
  //         dispatch(dataActions.setCouponCode(coupon))
  //         setNewProductPrice(response.data.new_price)
  //       }
  //     })
  //     .catch((err) => toastService.error(err.response?.data?.message || err.message))
  //     .finally(() => setIsApplyCouponLoading(false))
  // }

  const getSubscriptionPlan = () => {
    if (userData.is_past_due) {
      return {
        statusClassName: "danger",
        statusText: "Past Due"
      }
    } else if (userData.is_subscribed) {
      return {
        statusClassName: "active",
        statusText: "Active"
      }
    } else {
      return {
        statusClassName: "",
        statusText: "Trial Period"
      }
    }
  }

  const { statusClassName, statusText } = getSubscriptionPlan()

  const handleConfirmActivateSubscription = () => setIsNeedRefresh(true)

  return (
    <div className="subscription-plan">
      <div className="subscription-plan-header">
        <div className="subscription-plan-header-icon">
          <BasicPlanIcon />
          <div>Weekly Plan</div>
        </div>
        <div className={`subscription-plan-header-tag ${statusClassName}`}>{statusText}</div>
      </div>
      <div className="subscription-plan-container">
        <div className="subscription-plan-info">
          <div className="subscription-plan-info-first">
            <div className="subscription-plan-info-item">
              <Check /> Unlimited Employees
            </div>
            <div className="subscription-plan-info-item">
              <Check /> Monitor Weekly Income
            </div>
            <div className="subscription-plan-info-item">
              <Check /> Pay Per Stop & Per Package
            </div>
            <div className="subscription-plan-info-item">
              <Check /> Stop Threshold Per Employee
            </div>
            <div className="subscription-plan-info-item">
              <Check /> 6th Day Bonus Pay
            </div>
          </div>
          <div>
            <div className="subscription-plan-info-item">
              <Check /> Analyze profit margins
            </div>
            <div className="subscription-plan-info-item">
              <Check /> Streamline Financial Management Weekly
            </div>
            <div className="subscription-plan-info-item">
              <Check /> Export CSV to PayChex, ADP, Gusto
            </div>
            <div className="subscription-plan-info-item">
              <Check /> Fuel Calculation Per Employee Per CSA
            </div>
          </div>
        </div>
        <div>
          {newProductPrice && (
            <div className="subscription-plan-cost" style={{ marginBottom: "12px" }}>
              <div>old price:</div>
              <div className="subscription-plan-number">
                {formatMoney(stationCount * productPrice, true)}
              </div>
            </div>
          )}

          <div className="subscription-plan-cost">
            {newProductPrice && <div>new price:</div>}
            <div className="subscription-plan-number">
              {formatMoney(
                stationCount > 0 && !newProductPrice
                  ? stationCount * productPrice
                  : newProductPrice || productPrice,
                true
              )}
            </div>
            <div>per week</div>
          </div>
        </div>
      </div>
      {userData.is_subscribed || userData.is_past_due ? (
        userData.on_generic_trial || dayjs(userData.trial_ends_at).isAfter(dayjs()) ? (
          <div className="subscription-plan-footer cancel">Your Subscription starts at Friday</div>
        ) : (
          userData.can_cancel_subscription && (
            <div className="subscription-plan-footer cancel">
              <button onClick={handleCancelOpen} disabled={isCancelLoading}>
                Cancel Subscription
              </button>
            </div>
          )
        )
      ) : (
        <div className="subscription-plan-footer">
          <div className="coupon-container">
            {/* {isCouponShow && (
              <>
                {newProductPrice ? (
                  <div className="subscription-plan-coupon-applied">
                    This coupon applied: <b>{couponCode}</b>
                  </div>
                ) : (
                  <>
                    <div className="form-item">
                      <div className="input-box">
                        <TextField
                          type="text"
                          placeholder="Enter Coupon code"
                          name="coupon"
                          value={coupon}
                          onChange={handleCouponChange}
                        />
                      </div>
                    </div>
                    <button
                      className="confirm-modal-btn company coupon-btn"
                      onClick={handleApplyCoupon}
                      disabled={isApplyCouponLoading || coupon.length < 1}>
                      Apply
                    </button>
                  </>
                )}
              </>
            )} */}
          </div>
          <button onClick={handleOpenAddSubscription}>
            Activate you plan <ArrowUp />
          </button>
        </div>
      )}

      {/* <SubscriptionAddPaymentMethodModal
        open={isOpenAddSubscription}
        handleClose={handleCloseAddSubscription}
        setIsNeedRefresh={setIsNeedRefresh}
        handleSubscribe={handleOpenFinishSubscription}
        isFirstCard={emailFromQuery === "true" || !cards.length}
      />

      <SubscriptionModal
        open={isOpenFinishSubscription}
        handleClose={handleCloseFinishSubscription}
        setIsNeedRefresh={setIsNeedRefresh}
        handleAddCard={handleOpenModalAddSubscription}
        cards={cards}
        isLoading={isLoading}
      /> */}
      <ActivateSubscriptionModal
        open={isOpenAddSubscription}
        handleClose={handleCloseAddSubscription}
        handleConfirm={handleConfirmActivateSubscription}
        productPrice={productPrice}
      />
      <ConfirmDeleteModal
        open={isOpenDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleConfirmDelete}
        text="Are you sure you wan't to Cancel Subscription?"
        confirmButtonText="Cancel Subscription"
        isLoading={isCancelLoading}
        icon={<CloseIcon className="confirm-modal-icon cancel-subscription" />}
      />
    </div>
  )
}

export default SubscriptionPlan
