import "../components/run-payroll/payroll-process.scss"

import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import RunPayrollProcessStep from "../components/run-payroll/RunPayrollProcessStep"
import RunPayrollStep from "../components/run-payroll/RunPayrollStep"
import stepperData from "../components/run-payroll/steps-content"
import axios from "../axios"
import toastService from "../services/toastService"
import { formatDate, getPayrollPeriodString } from "../helpers/helpers"
import Loading from "../components/common/Loading"
import DefaultPageHeader from "../components/shared/DefaultPageHeader"
import {
  actions as breadCrumbsActions
} from "../store/bread-crumbs/bread-crumbs.reducers"
import { useDispatch } from "react-redux"

const stepperItems = ["Charge Statement", "Weekly Service Worksheet", "Time Card", "Fuel"]

const RunPayrollProcess = () => {
  const dispatch = useDispatch();
  const { payrollId } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [payroll, setPayroll] = useState(null)
  const [allFiles, setAllFiles] = useState([])
  const [filesCount, setFilesCount] = useState({})
  const [step, setStep] = useState(1)
  const [completedSteps, setCompletedSteps] = useState([])
  const [visitedSteps, setVisitedSteps] = useState([1])

  useEffect(() => {
    fetchPayroll(payrollId)
  }, [payrollId])

  useEffect(() => {
    getAllUploadedFiles()
  }, [payroll])

  useEffect(() => {
    if (!filesCount.statements) return
    let steps = []

    if (filesCount.statements.uploaded >= filesCount.statements.total) {
      steps = [...steps, 1]
    }
    if (filesCount.dsw.uploaded >= filesCount.dsw.total) {
      steps = [...steps, 2]
    }
    if (filesCount["time-card"].uploaded >= filesCount["time-card"].total) {
      steps = [...steps, 3]
    }
    if (filesCount.fuel.uploaded >= filesCount.fuel.total) {
      steps = [...steps, 4]
    }

    const haveSomeStatements = payroll?.statements.length > 0;
    setCompletedSteps(steps)
    if (haveSomeStatements) {
      setVisitedSteps([1, 2, 3, 4]) // visited if anything is processed
    }

    if(payroll){
      dispatch(
        breadCrumbsActions.setBreadCrumbs([
          {
            name: "Payroll",
            url: `/run-payroll`
          },
          {
            name: getPayrollPeriodString(payroll.date),
            url: haveSomeStatements ? `/run-payroll-preview/${payrollId}` : null
          },
          {
            name: "Upload Data",
            url: `/`
          },
        ])
      );
    }

  }, [filesCount])

  const handleUpdatePayroll = () => {
    fetchPayroll(payrollId)
    getAllUploadedFiles()
  }

  const fetchPayroll = (payrollId) => {
    setIsLoading(true)
    axios
      .get(`/payroll/${payrollId}`)
      .then((res) => {
        const data = res.data
        setPayroll(data)
      })
      .catch((err) => {
        toastService.error(err.response?.data?.message || err.message)
      })
      .finally(() => setIsLoading(false))
  }

  const getAllUploadedFiles = () => {
    axios
      .get(`/payroll/${payrollId}/files/all`)
      .then((res) => {
        if (res.data) {
          const total_csa_number = payroll?.current_csa_number
          const total_companies_number = payroll?.total_companies_number

          setAllFiles(res.data)

          let dataArr = []
          for (const [key, value] of Object.entries(res.data)) {
            let total = total_csa_number
            if (key === "time-card") total = total_companies_number
            if (key === "fuel") total = 1

            dataArr[key] = {
              uploaded: value.length,
              total: total
            }
          }
          setFilesCount(dataArr)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    // .finally(() => setIsFilesLoading(false))
  }

  const handleStepChange = (newStep) => {
    // in case we already were on this step - let visit
    if (visitedSteps.includes(newStep)) {
      setStep(newStep)
    }

    // if ([3, 4].includes(newStep) && !completedSteps.includes(1) && !completedSteps.includes(2)) {
    //   return
    // }

    setStep(newStep)
  }

  const handleBack = () => setStep(step - 1)
  const handleNext = () => {
    const nextStep = step + 1
    if (!visitedSteps.includes(nextStep)) {
      setVisitedSteps([...visitedSteps, nextStep])
    }

    // request to endpoint
    setStep(nextStep)
  }

  const handleAddCompletedStep = (stepIndex) => setCompletedSteps([...completedSteps, stepIndex])

  const stepsProps = {
    handleBack,
    handleNext,
    activeStep: step,
    handleAddCompletedStep,
    payroll,
    allFiles,
    handleUpdateAllFiles: handleUpdatePayroll
  }

  const ChargeStatementStep = (
    <RunPayrollStep content={stepperData.chargeStatementStep} step={1} {...stepsProps} />
  )

  const WSWStep = (
    <RunPayrollStep
      content={stepperData.wswStep}
      completedSteps={completedSteps || []}
      step={2}
      {...stepsProps}
    />
  )

  const TimeCardStep = (
    <RunPayrollStep content={stepperData.timeCardStep} step={3} {...stepsProps} />
  )

  const FuelStep = (
    <RunPayrollStep content={stepperData.fuelStep} lastStep step={4} {...stepsProps} />
  )

  const getCurComponent = () => {
    switch (step) {
      case 1:
        return ChargeStatementStep
      case 2:
        return WSWStep
      case 3:
        return TimeCardStep
      case 4:
        return FuelStep
      default:
        return ChargeStatementStep
    }
  }

  if (isLoading) {
    return <Loading />
  }

  const periodDate = payroll
    ? formatDate(payroll.date, false) + " - " + formatDate(payroll.date, true, 6)
    : null;

  return (

    <div className="run-payroll-process">
      <DefaultPageHeader
        title={`Pay Period ${periodDate}`}
        actionButtons={[]}
        page={null} // multiple pages
      />

      <div className="container">
        <div className="stepper-container">
          {stepperItems.map((el, index) => {
            let countKey = "statements"
            if (index === 1) countKey = "dsw"
            if (index === 2) countKey = "time-card"
            if (index === 3) countKey = "fuel"

            return (
              <RunPayrollProcessStep
                key={el}
                name={el}
                step={step}
                number={index + 1}
                completedSteps={completedSteps}
                visitedSteps={visitedSteps}
                handleStepChange={handleStepChange}
                isLastStep={stepperItems.length === index + 1}
                countItem={filesCount[countKey]}
              />
            )
          })}
        </div>

        {getCurComponent()}
      </div>
    </div>
  )
}

export default RunPayrollProcess
