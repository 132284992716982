import { useState } from "react"
import { ReactComponent as PaymentMethodIcon } from "../../../assets/images/settings/payment-method.svg"
import { ReactComponent as NoPayment } from "../../../assets/images/settings/no-payment.svg"
import { ReactComponent as AddIcon } from "../../../assets/images/settings/add.svg"
import Loading from "../../common/Loading"
import SubscriptionPaymentMethodItem from "./SubscriptionPaymentMethodItem"
import ActivateSubscriptionModal from "../../modals/ActivateSubscriptionModal"
import SubscriptionAddPaymentMethodModal from "../../modals/SubscriptionAddPaymentMethodModal"

const SubscriptionPaymentMethod = ({ isLoading, cards, setIsNeedRefresh, productPrice }) => {
  const [isOpenAddSubscription, setIsOpenAddSubscription] = useState(false)
  const [isOpenAddNewCard, setIsOpenAddNewCard] = useState(false)

  const isFirstCard = !cards.length

  const handleCloseAddSubscription = () => setIsOpenAddSubscription(false)
  const handleOpenAddSubscription = () => {
    if (isFirstCard) {
      setIsOpenAddSubscription(true)
    } else {
      setIsOpenAddNewCard(true)
    }
  }

  const handleConfirmActivateSubscription = () => setIsNeedRefresh(true)

  return (
    <div className="subscription-plan">
      <div className="subscription-plan-header">
        <div className="subscription-plan-header-icon">
          <PaymentMethodIcon />
          <div>Payment</div>
        </div>
        {(isFirstCard || cards.length < 2) && (
          <div className="subscription-plan-header-add" onClick={handleOpenAddSubscription}>
            <AddIcon /> Add new method
          </div>
        )}
      </div>

      <div className="subscription-plan-container payment-method">
        {isLoading ? (
          <Loading />
        ) : isFirstCard ? (
          <div className="payment-method-empty">
            <NoPayment />
            <div>No payment method</div>
          </div>
        ) : (
          <div className="subscription-payment-method-items">
            {cards.map((el) => (
              <SubscriptionPaymentMethodItem
                key={el.id}
                setIsNeedRefresh={setIsNeedRefresh}
                card={el}
              />
            ))}
          </div>
        )}
      </div>
      <ActivateSubscriptionModal
        open={isOpenAddSubscription}
        handleClose={handleCloseAddSubscription}
        handleConfirm={handleConfirmActivateSubscription}
        productPrice={productPrice}
      />
      <SubscriptionAddPaymentMethodModal
        open={isOpenAddNewCard}
        handleClose={() => setIsOpenAddNewCard(false)}
        setIsNeedRefresh={setIsNeedRefresh}
      />
    </div>
  )
}

export default SubscriptionPaymentMethod
