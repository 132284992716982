import "../assets/css/run-payroll.css"

import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import TitleInfo from "./../components/common/TitleInfo"
import axios from "../axios"
import EmptyState from "../components/run-payroll/EmptyState"
import { getPayrollList } from "../store/payroll/payroll.selectors"
import { actions } from "../store/payroll/payroll.reducers"
import Loading from "../components/common/Loading"
import { actions as breadCrumbsActions } from "../store/bread-crumbs/bread-crumbs.reducers"
import { useTableSortItem } from "../hooks/useTableSortItem"
import MissingEmployeeModal from "../components/modals/MissingEmployeeModal"
import AddMissingEmployeeModal from "../components/modals/AddMissingEmployeeModal"
import toastService from "../services/toastService"
import { MenuItem, Select } from "@mui/material"
import { KeyboardArrowDown } from "@mui/icons-material"
import PayrollList from "../components/run-payroll/PayrollList"
import { useProductPrice } from "../hooks/useProductPrice"

const RunPayroll = () => {
  const dispatch = useDispatch()

  const { productPrice } = useProductPrice()

  const [filesFormData, setFilesFormData] = useState()
  const [isUploading, setIsUploading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [displayBy, setDisplayBy] = useState(4)
  const { sortItem, setSortItem } = useTableSortItem("run-payroll")
  const [needAddEmployeeList, setNeedAddEmployeeList] = useState([])
  const [isNeedAddEmployee, setIsNeedAddEmployee] = useState(false)
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false)

  const payrolls = useSelector(getPayrollList)

  useEffect(() => {
    dispatch(
      breadCrumbsActions.setBreadCrumbs([
        {
          name: "Payroll",
          url: `/run-payroll`
        }
      ])
    )
  }, [])

  useEffect(() => {
    const handler = setTimeout(() => fetchPayrollList(), 500)

    return () => {
      clearTimeout(handler)
    }
  }, [displayBy, sortItem])

  useEffect(() => {
    if (filesFormData) {
      setIsUploading(true)
      axios
        .post("/payroll/upload", filesFormData)
        .then((res) => {
          if (res) {
            fetchPayrollList()
            toastService.success(res.message)
          }
        })
        .catch((err) => {
          if (
            (err.response?.data?.message.includes("Missing Employees") ||
              err.message.includes("Missing Employees")) &&
            err.response?.data?.data
          ) {
            setIsNeedAddEmployee(true)
            setNeedAddEmployeeList(
              Object.entries(err.response?.data.data).reduce((acc, el) => {
                return [...acc, { ...el[1], fedex_id: el[0] }]
              }, [])
            )
          } else {
            toastService.error(err.response?.data?.message || err.message)
          }
        })
        .finally(() => setIsUploading(false))
    }
  }, [filesFormData])

  const fetchPayrollList = () => {
    setLoading(true)

    axios
      .get(
        `/payroll?filter=${displayBy}&field=${sortItem?.name || ""}&direction=${
          sortItem?.direction || ""
        }`
      )
      .then((response) => dispatch(actions.setPayrollList(response.data.payrolls)))
      .catch((err) => toastService.error(err.response?.data?.message || err.message))
      .finally(() => setLoading(false))
  }

  const handleRangeChange = (e) => {
    setDisplayBy(e.target.value)
    localStorage.setItem("runPayrollFilterBy", e.target.value)
    setSortItem()
  }

  const handleSortChange = (sortItemName, type) =>
    setSortItem({
      name: sortItemName,
      direction: type
    })

  const handleIsNeedAddEmployeeClose = () => {
    setIsNeedAddEmployee(false)
    setNeedAddEmployeeList([])
  }

  const handleAddEmployeeOpenClose = () => {
    setAddEmployeeOpen(false)
    setNeedAddEmployeeList([])
  }

  const handleConfirm = () => {
    setIsNeedAddEmployee(false)
    setAddEmployeeOpen(true)
  }

  useEffect(() => {
    const runPayrollFilterBy = localStorage.getItem("runPayrollFilterBy")

    if (runPayrollFilterBy) {
      setDisplayBy(+runPayrollFilterBy)
    }
  }, [])

  return (
    <>
      <TitleInfo
        title="Payroll"
        subTitle={
          <>
            <p style={{ margin: "0" }}>
              Calculate payroll by uploading required files for each pay period.
            </p>
            <p style={{ margin: "0" }}> New Payroll Week is generating every Saturday</p>
          </>
        }
        contentRight={
          <Select
            className="payroll-table-select"
            value={displayBy}
            onChange={handleRangeChange}
            IconComponent={KeyboardArrowDown}>
            <MenuItem value={4}>Month</MenuItem>
            <MenuItem value={24}>6 Month</MenuItem>
            <MenuItem value={48}>Year</MenuItem>
          </Select>
        }
      />

      {loading ? (
        <div className="run-payroll-height">
          <Loading />
        </div>
      ) : payrolls.length ? (
        <PayrollList
          payrolls={payrolls}
          displayBy={displayBy}
          handleSortChange={handleSortChange}
          activeSortedItem={sortItem}
          handleUpdateList={fetchPayrollList}
          productPrice={productPrice}
        />
      ) : (
        <EmptyState />
      )}

      <MissingEmployeeModal
        open={isNeedAddEmployee}
        handleClose={handleIsNeedAddEmployeeClose}
        handleConfirm={handleConfirm}
        needAddEmployeeList={needAddEmployeeList}
      />

      <AddMissingEmployeeModal
        open={addEmployeeOpen}
        handleClose={handleAddEmployeeOpenClose}
        needAddEmployeeList={needAddEmployeeList}
      />
    </>
  )
}

export default RunPayroll
