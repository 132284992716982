import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { actions as dataActions } from "../store/data/data.reducers";
import toastService from "../services/toastService";

const echo = new Echo({
  broadcaster: "pusher",
  // eslint-disable-next-line no-undef
  key: process.env.REACT_APP_PUSHER_APP_KEY,
  // eslint-disable-next-line no-undef
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  forceTLS: true
});

export const useWebSocket = (user, isProcessPayrollStarted) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-undef
  Pusher.logToConsole = process.env.NODE_ENV !== 'production';

  useEffect(() => {
    if (echo && isProcessPayrollStarted && Object.keys(user).length) {
      const organizationID = user.organization_id;

      // Create WebSocket channels specific to the organization
      const failedChannel = echo.channel(`paychex.organization.${organizationID}.process_payroll_failed`);
      const successChannel = echo.channel(`paychex.organization.${organizationID}.process_payroll`);

      // Listen for payroll processing failure events
      failedChannel.listen('PaychexPayrollProcessedFailed', () => {
        console.log('PaychexPayrollProcessedFailed');
        dispatch(dataActions.setIsProcessPayrollStarted(false));
        toastService.error('Paychex Process Payroll Failed. Please try again later');
      });

      // Listen for payroll processing success events
      successChannel.listen('PaychexPayrollProcessedSuccessfully', () => {
        console.log('PaychexPayrollProcessedSuccessfully');
        dispatch(dataActions.setIsProcessPayrollStarted(false));
        toastService.success('Process Payroll Completed');
      });

      // Clean up listeners when component is unmounted or dependencies change
      return () => {
        failedChannel.stopListening('PaychexPayrollProcessedFailed');
        successChannel.stopListening('PaychexPayrollProcessedSuccessfully');
      };
    }
  }, [echo, user.organization_id, isProcessPayrollStarted]); // Dependencies: reinitialize only when these values change

  return { echo };
};
