import { CircularProgress } from "@mui/material"

import "./common.scss"

const Loading = ({ style = null }) => {
  return (
    <div className="loader">
      <CircularProgress style={style} />
    </div>
  )
}

export default Loading
