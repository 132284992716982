import React from "react"
import { ReactComponent as Delete } from "../../assets/images/run-payroll/delete-filled.svg"
import { ReactComponent as XlsFileIcon } from "../../assets/images/run-payroll/xls-file.svg"
import { transformBytesInToKilobytes } from "../../helpers/helpers"

const getCurFileIcon = (type) => {
  switch (type) {
    case "csv":
    case "text/csv":
      return <XlsFileIcon />
    default:
      return <XlsFileIcon />
  }
}

const RunPayrollStepFile = ({ fileData, handleDeleteFile, canDeleteFileProcessed = false, handleDeleteFileProcessed = null }) => {
  return (
    <div className="step-file step-error">
      {getCurFileIcon(fileData.type)}
      <div className="text-container">
        <div className="title">{fileData.name}</div>
        <div className="description">{transformBytesInToKilobytes(fileData.size)}KB</div>
      </div>
      {canDeleteFileProcessed && (
          handleDeleteFileProcessed && (
              <button className="step-file-btn" onClick={handleDeleteFileProcessed.bind(null, fileData)}>
                <Delete/>
              </button>
          )
      )}
      {
        !fileData.isProcessed ? (
            handleDeleteFile && (
                <button className="step-file-btn" onClick={handleDeleteFile.bind(null, fileData)}>
              <Delete />
            </button>)
        ) : null
      }

    </div>
  )
}

export default RunPayrollStepFile
