import { useElements, useStripe } from "@stripe/react-stripe-js"
import Loading from "../../common/Loading"
import Button from "../../UI/Button/Button"

const ActivateSubscriptionModalButtons = ({
  handleSubmit,
  onSubmit,
  handleCurClose,
  isSubscriptionLoading,
  handleCheckIfCardError
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const submitClick = () => {
    if (handleCheckIfCardError()) {
      handleSubmit(onSubmit.bind(null, stripe, elements))()
    }
  }

  return (
    <div className="confirm-modal-btn-wrapper confirm-modal-button-wrapper">
      <Button onClick={handleCurClose} type="grey-bg" text="Cancel" className="half-width" />
      <Button
        onClick={submitClick}
        type="green-bg"
        bottomType="submit"
        className="half-width"
        disabled={isSubscriptionLoading}
        text={isSubscriptionLoading ? <Loading /> : "Activate"}
      />
    </div>
  )
}

export default ActivateSubscriptionModalButtons
