import { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormHelperText, TextField } from "@mui/material";
import GoogleBig from "../../assets/images/login/google-big.png";
import AppleBig from "../../assets/images/login/apple-big.png";
import Loading from "../common/Loading";
import axios from "../../axios";
import { ReactComponent as EmailConfirmation } from "../../assets/images/login/email-confirmation.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import toastService from "../../services/toastService";

const styles = {
  error: {
    "& div input": {
      borderColor: "red !important"
    }
  }
};

const schema = yup.object().shape({
  organization: yup.string().required("Organization Name is required!"),
  first_name: yup.string().required("First Name is required!"),
  last_name: yup.string().required("Last Name is required!"),
  email: yup.string().email("Invalid format").required("Email is required!"),
  password: yup
    .string()
    .required("Password is required!")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  is_agreed: yup.bool().oneOf([true], "You must accept the terms and privacy")
});

const SignUpPage = ({ handleSignIn }) => {
  const [loading, setLoading] = useState(false);
  const [isResentLoading, setIsResentLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [savedEmail, setSavedEmail] = useState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
    watch
  } = useForm({
    mode: "all",
    defaultValues: {
      first_name: "",
      organization: "",
      last_name: "",
      email: "",
      password: "",
      is_agreed: false
    },
    resolver: yupResolver(schema)
  });

  const { email } = watch();

  const onSubmit = (values) => {
    const body = { ...values, ...(phone.length > 0 && { phone: phone }) };
    delete body.is_agreed;

    setLoading(true);

    axios
      .post("/auth/signup", body)
      .then((res) => {
        if (!res.success) {
          toastService.error(res.message)
          reset();
          return;
        } else {
          toastService.success(res.message)
          setSavedEmail(email);
          reset();
        }
      })
      .catch((err) =>
        toastService.error(`${Object.values(err.response.data.errors).join("")}`)
      )
      .finally(() => setLoading(false));
  };

  const handleGoogleLogin = () => {
    axios
      .get("/auth/social/google/redirect")
      .then((res) => {
        if (res) {
          window.open(res, "_self");
        }
      })
      .catch((err) => {
        if (err.response?.status !== 401 && err.response?.status !== 422) console.log(err);
      });
  };

  const handleResendVerification = () => {
    setIsResentLoading(true);

    axios
      .post(`/auth/email/resend-verification?email=${savedEmail}`)
      .then((res) => {
        toastService.success(res.data.message)
      })
      .catch((err) =>
        toastService.error(`${Object.values(err.response.data.errors).join("")}`)
      )
      .finally(() => setIsResentLoading(false));
  };

  const handleClose = () => {
    setSavedEmail();
    handleSignIn();
  };

  if (savedEmail) {
    return (
      <div className="email-confirmation">
        <div className="email-confirmation-container">
          <div className="email-confirmation-close" onClick={handleClose}>
            <CloseIcon />
          </div>
          <EmailConfirmation />
          <div className="email-confirmation-title">Email Confirmation</div>
          <div className="email-confirmation-subtitle">
            We have sent email to <b>{savedEmail}</b> to confirm the validity of our email address.
            After receiving the email follow the link provided to complete you registration
          </div>
          <div className="email-confirmation-divider" />
          <div className="email-confirmation-footer">
            <div>If you not got any mail</div>
            <button disabled={isResentLoading} onClick={handleResendVerification}>
              Resend confirmation mail
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="inner-sec sign-up-form-wrapper">
      <h1>Get Started Now</h1>
      <p>Enter your credentials to access your account</p>
      <div className="login-options">
        <button onClick={handleGoogleLogin}>
          <img src={GoogleBig} alt="" /> Log in with Google
        </button>
        <button disabled>
          <img src={AppleBig} alt="" /> Log in with Apple
        </button>
      </div>
      <div className="or-text sign-up-form">
        <span>or</span>
      </div>
      <form className="login-form sign-up-form">
        <div className="input-items">
          <label className={errors.first_name?.message && "error"}>First Name *</label>
          <div className="input-box">
            <TextField
              sx={errors.first_name?.message ? styles.error : {}}
              type="text"
              name="first_name"
              required
              placeholder="Enter First Name"
              {...register("first_name")}
              error={touchedFields.first_name && !!errors.first_name?.message}
              helperText={errors.first_name?.message}
            />
          </div>
        </div>
        <div className="input-items">
          <label className={errors.last_name?.message && "error"}>Last Name *</label>
          <div className="input-box">
            <TextField
              sx={errors.last_name?.message ? styles.error : {}}
              type="text"
              name="last_name"
              required
              placeholder="Enter Last Name"
              {...register("last_name")}
              error={touchedFields.last_name && !!errors.last_name?.message}
              helperText={errors.last_name?.message}
            />
          </div>
        </div>
        <div className="input-items">
          <label className={errors.organization?.message && "error"}>Organization Name *</label>
          <div className="input-box">
            <TextField
              sx={errors.organization?.message ? styles.error : {}}
              type="text"
              name="organization"
              required
              placeholder="Enter Organization Name"
              {...register("organization")}
              error={touchedFields.organization && !!errors.organization?.message}
              helperText={errors.organization?.message}
            />
          </div>
        </div>
        <div className="input-items">
          <label>Phone</label>
          <div className="input-box">
            <InputMask
              mask="(999) 999-9999"
              value={phone}
              disabled={false}
              onChange={(data) => setPhone(data.target.value)}
              maskChar=" ">
              {() => <TextField className="input-mask" />}
            </InputMask>
          </div>
        </div>
        <div className="input-items">
          <label className={errors.email?.message && "error"}>Email Address *</label>
          <div className="input-box">
            <TextField
              sx={errors.email?.message ? styles.error : {}}
              type="email"
              name="email"
              required
              placeholder="Enter Email Address"
              {...register("email")}
              error={touchedFields.email && !!errors.email?.message}
              helperText={errors.email?.message}
            />
          </div>
        </div>
        <div className="input-items">
          <label className={errors.password?.message && "error"}>Password *</label>
          <div className="input-box">
            <TextField
              sx={errors.password?.message ? styles.error : {}}
              type="password"
              name="password"
              required
              placeholder="Enter Password"
              {...register("password")}
              error={touchedFields.password && !!errors.password?.message}
              helperText={errors.password?.message}
            />
          </div>
        </div>
        <div className="agree-items" style={{display:'block'}}>
          <input type="checkbox" name="is_agreed" required {...register("is_agreed")} />
          <label className={errors.is_agreed?.message && "error"}>
            I agree to the <a href="https://fleetwage.com/privacy">Terms & Privacy</a> and to receive marketing messages from Twilio at the phone number provided above. I understand I will receive up to 2 messages per month. Data rates may apply. You can always reply STOP to opt out.
          </label>
          {touchedFields.is_agreed && errors.is_agreed?.message && (
            <FormHelperText className={errors.is_agreed?.message && "error"}>
              {errors.is_agreed?.message}
            </FormHelperText>
          )}
        </div>
        <div className="submit-btn sign-up-form">
          {loading ? (
            <Loading />
          ) : (
            <input
              type="submit"
              value="Sign up"
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
            />
          )}
        </div>
      </form>
      <div className="have-account-sec">
        Have an account? <button onClick={handleSignIn}>Sign in</button>
      </div>
    </div>
  );
};

export default SignUpPage;
