import "../../assets/css/modal.css"
import { Modal } from "@mui/material"
import { ReactComponent as UserSearchIcon } from "../../assets/images/run-payroll/user-search.svg"
import { ReactComponent as CopyIcon } from "../../assets/images/run-payroll/copy.svg"
import toastService from "../../services/toastService"
import { useEffect, useState } from "react"
import axios from "../../axios"
import { useSelector } from "react-redux"
import { getUser } from "../../store/auth/auth.selectors"
import Loading from "./../common/Loading"

const MissingEmployeeModal = ({ open, handleClose, handleConfirm, needAddEmployeeList }) => {
  const userData = useSelector(getUser)

  const [isPaychexIntegrations, setIsPaychexIntegrations] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!open) return
    const haveDirectIntegrations = userData.directIntegrations

    // make sure we have paychex integration
    if (haveDirectIntegrations) {
      axios
        .get("/integrations/paychex")
        .then((response) => {
          setIsPaychexIntegrations(
            response?.data?.some((integration) => integration.data.approved) || false
          )
        })
        .catch((err) => toastService.error(err.response?.data?.message || err.message))
        .finally(() => setIsLoading(false))
    } else isLoading && setIsLoading(false)
  }, [open])

  const handleCopy = () => {
    navigator.clipboard.writeText(
      needAddEmployeeList
        .map((el) => `${el.fedex_id} - ${el.first_name} ${el.last_name}`)
        .join(", ")
    )

    toastService.success("Copied")
  }

  const handleClickConfirm = () => {
    if (isPaychexIntegrations) {
      if (isPaychexIntegrations) {
        window.open("https://myapps.paychex.com", "_blank")
      }
    } else {
      handleConfirm()
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="confirm-modal missing-employee">
        <div className="confirm-modal-wrapper">
          <div className="confirm-modal-icon-wrapper yellow">
            <UserSearchIcon className="confirm-modal-icon" />
          </div>

          {isLoading ? (
            <Loading />
          ) : (
            <>
              {isPaychexIntegrations ? "Missing Paychex Data" : "Missing Employee Data"}

              <div className="confirm-modal-subtitle missing-employee">
                {isPaychexIntegrations
                  ? "Some employees are missing in your account. Since your account syncs directly with Paychex, employee updates must be made there. To fix this, log in to your Paychex account to add or update the following employees:"
                  : "It looks like some employee details are missing. You can add them now or skip this step, but payroll generation won’t be possible until all employee data is complete. If you close this window, you’ll need to start the payroll process again."}
              </div>

              <div className="missing-employee-list">
                List of missing employees:{" "}
                {isPaychexIntegrations && <CopyIcon onClick={handleCopy} />}
              </div>
              <div className="missing-employee-list-container">
                {needAddEmployeeList.map((el, index) => (
                  <div key={el.fedex_id} className="missing-employee-list-item">
                    {index + 1}. {el.first_name} {el.last_name} (FedEx ID {el.fedex_id})
                  </div>
                ))}
              </div>

              <div className="confirm-modal-btn-wrapper">
                <button className="confirm-modal-btn cancel" onClick={handleClose}>
                  Cancel
                </button>
                <button className="confirm-modal-btn recalculate" onClick={handleClickConfirm}>
                  {isPaychexIntegrations ? "Go to Paychex" : "Add Employees"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default MissingEmployeeModal
