import "../../assets/css/modal.css";
import {FormHelperText, MenuItem, Modal, Select} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../axios";
import toastService from "../../services/toastService";
import Loading from "../common/Loading";
import InputField from "../common/InputField";
import {KeyboardArrowDown} from "@mui/icons-material";

const schema = yup.object().shape({
  value: yup.string().required("Date is required!")
});

const EmployeeAddDateModal = ({ open, handleClose, employee, statementId, free_days, fetchStatementList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, touchedFields },
  } = useForm({
    mode: "all",
    defaultValues: {
      value: "",
    },
    resolver: undefined,
  });

  useEffect(() => {
    if (free_days && Object.entries(free_days).length === 1) {
      const singleDateKey = Object.keys(free_days)[0];
      setDate(singleDateKey);
    }
  }, [free_days]);

  useEffect(() => {
    setValue("value", employee?.email, {
      shouldValidate: true,
      shouldDirty: true
    });
  }, [open]);

  const handleMainClose = () => {
    handleClose();
    reset();
  };

  const handleAddDate = (e) => {
    const targetValue = e.target?.value;

    setDate(targetValue);
  };
  const onSubmit = () => {
    setIsLoading(true);

    axios
        .post(`/employee-daily-summary`, {
          statement_id: statementId,
          employee_id: employee?.id,
          date: date
        })
        .then((response) => {
          if (response.success) {
            handleMainClose();
            fetchStatementList();
            toastService.success("Day successfully added.");
          }
        })
        .catch((err) => {
          const message = err.response?.data?.errors
              ? Object.values(err.response?.data?.errors).join("\n")
              : err.response?.data?.message || err?.message;
          toastService.error(message);
        })
        .finally(() => {
          setIsLoading(false);
        });
  };

  return (
    <Modal open={open} onClose={handleMainClose}>
      <div className="confirm-modal">
        <div className="confirm-modal-wrapper add-member-modal">
          <form className="profile-form-sec add-member-modal-form">
            <h3 style={{ fontSize: "18px", marginBottom: "5px", fontWeight: "800" }}>Add Date</h3>
            <span style={{ fontSize: "12px", fontWeight: "400"}}>Choose day from days that are still not in the list</span>

            <div className="form-item" style={{ marginBottom: "0", marginTop: "1.5rem"}}>
              <div className="input-box">
                <Select
                    style={{ width: "100%" }}
                    required
                    className="employees-select"
                    name="date"
                    value={Object.entries(free_days || {}).length === 1 ? Object.keys(free_days)[0] : date || ""}
                    IconComponent={KeyboardArrowDown}
                    onChange={handleAddDate}
                    displayEmpty>
                  <MenuItem value="" disabled>
                    Select Date
                  </MenuItem>
                  {free_days &&
                    Object.entries(free_days).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
          </form>

          <div className="add-member-form-btn-wrapper">
            <button className="btn-style blank-btn add-popup-cancel" onClick={handleMainClose}>
              Cancel
            </button>
            <button
                className="btn-style add-employee-btn loading-style"
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}>
              {isLoading ? <Loading/> : "Add"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmployeeAddDateModal;
